import { ExtractionSchemaContextWrapper } from "../fabrk/hooks/ExtractionSchemaContext";
import ExtractionParametersForm from "./ExtractionParametersForm";
import { useToolContext } from "./hooks/ToolContext";
import StandardParametersForm from "./StandardParametersForm";

function ParametersContainer() {
  const { tool } = useToolContext();

  switch (tool?.type) {
    case "extraction":
      return (
        <ExtractionSchemaContextWrapper>
          <ExtractionParametersForm />
        </ExtractionSchemaContextWrapper>
      );
    default:
      return <StandardParametersForm />;
  }
}

export default ParametersContainer;
