export function HeadingBase({ children }: { children: React.ReactNode }) {
  return (
    <dt className="text-md font-semibold text-skin-muted sm:w-40 sm:flex-shrink-0">
      {children}
    </dt>
  );
}

export function LabelBase({ children }: { children: React.ReactNode }) {
  return (
    <dt className="text-sm font-medium text-skin-muted sm:w-40 sm:flex-shrink-0">
      {children}
    </dt>
  );
}

export function DataValueBase({ children }: { children: React.ReactNode }) {
  return (
    <dd className="text-md mt-1 text-skin-base sm:col-span-2">{children}</dd>
  );
}
