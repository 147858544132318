type AdditionalPropertiesArray = ToolParameterOptions[];
type AdditionalPropertiesObject = Record<string, ToolParameterOptions>;

type ToolParameterOptions = {
  type: "string" | "number" | "boolean" | "array" | "object";
  description?: string;
  default?: string | number | boolean | any[];
  name: string;
};

export type ToolParametersWithAdditionalPropertiesArray =
  ToolParameterOptions & {
    additionalProperties: AdditionalPropertiesArray;
  };

export type ToolParametersWithAdditionalPropertiesObject =
  ToolParameterOptions & {
    additionalProperties: AdditionalPropertiesObject;
  };

export type ParameterFormValues = {
  parameters: ToolParametersWithAdditionalPropertiesArray[];
};

type NestedObject = {
  [key: string]: any;
};

export function createNestedObjectFromFormValues(
  formValues: ParameterFormValues,
): NestedObject {
  const result: NestedObject = {};

  formValues.parameters.forEach((param) => {
    result[param.name] = {
      type: param.type,
      description: param.description,
      default: param.default,
      ...(param.additionalProperties && {
        additionalProperties: handleAdditionalProperties(
          param.additionalProperties,
        ),
      }),
    };
  });

  return result;
}

function handleAdditionalProperties(
  additionalProperties: ToolParametersWithAdditionalPropertiesArray["additionalProperties"],
): NestedObject {
  const nestedObject: NestedObject = {};

  if (!additionalProperties) {
    return nestedObject;
  }

  additionalProperties.forEach((prop) => {
    nestedObject[prop.name] = {
      type: prop.type,
      description: prop.description,
      default: prop.default,
    };

    // If the additional property has nested properties, recursively handle them
    // @ts-ignore
    if (prop.type === "object" && prop.additionalProperties) {
      nestedObject[prop.name].additionalProperties = handleAdditionalProperties(
        // @ts-ignore
        prop.additionalProperties,
      );
    }
  });

  return nestedObject;
}
