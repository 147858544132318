/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from "react";
import { ActionContextWrapper } from "../../action/ActionContext";
import { useEventStreaming } from "../../message/StreamMessageContext";
import { useMessageContext } from "../hooks/MessageContext";
import AssistantMessageChatItem from "./AssistantMessageChatItem";
import MessageLoadingSkeleton from "./MessageLoadingSkeleton";
import UserMessageChatItem from "./UserMessageChatItem";

function MessagesContainer({ menuType }: { menuType: string }) {
  const { loading, messages, newMessage, messageCreatedTime } =
    useMessageContext();

  const { text, streaming, personaText, personaStreaming } =
    useEventStreaming();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loading || text) {
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [loading, text]);

  return (
    <div className="mx-auto flex w-3/4 flex-1 flex-col gap-10 overflow-y-auto bg-skin-fill px-2">
      {messages?.map((message, index) => {
        if (message.role === "user") {
          return (
            <div key={index}>
              <ActionContextWrapper>
                <UserMessageChatItem message={message} />
              </ActionContextWrapper>
            </div>
          );
        }
        if (message.role === "assistant") {
          return (
            <div key={index} className="flex flex-row">
              <AssistantMessageChatItem
                message={message}
                showMetadata={menuType === "meta"}
                userMessageId={messages[index - 1]?.id}
              />
            </div>
          );
        }
        return null;
      })}
      {(loading || streaming) && !text && <MessageLoadingSkeleton />}
      {personaStreaming && !personaText && <MessageLoadingSkeleton />}

      {text && (
        <div className="flex flex-row">
          <AssistantMessageChatItem
            message={{
              id: newMessage?.id || "",
              content: text,
              created_at: messageCreatedTime || "",
              role: "assistant",
            }}
            showMetadata={menuType === "meta"}
          />
        </div>
      )}
      {personaText && (
        <div className="flex flex-row">
          <UserMessageChatItem
            message={{
              id: newMessage?.id || "",
              content: personaText,
              created_at: messageCreatedTime || "",
              role: "user",
            }}
          />
        </div>
      )}
      <div ref={containerRef} />
    </div>
  );
}

export default MessagesContainer;
