/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { ExtractionSchemaRow } from "../../api/extractionSchema.type";
import { ChevronDown } from "../shared/icons/icons";
import EditExtractionSchema from "./EditExtractionSchema";

type AgentAccordionProps = ExtractionSchemaRow & {
  isOpen: boolean;
  handleOpen: () => void;
};

function ExtractionSchemaList({
  setSelectedSchema,
  schema,
  children,
}: {
  schema: ExtractionSchemaRow[] | undefined;
  setSelectedSchema?: (schema: ExtractionSchemaRow) => void;
  children?: React.ReactNode;
}) {
  const [list, setList] = useState<AgentAccordionProps[]>();

  const [isOpen, setIsOpen] = useState();

  function mapList(
    schema: ExtractionSchemaRow,
    index: number,
  ): AgentAccordionProps {
    if (!schema) {
      return {
        id: "",
        created_at: "",
        updated_at: "",
        name: "",
        schema: [],
        isOpen: false,
        handleOpen: () => {},
      };
    }
    return {
      ...schema,
      isOpen: false,
      handleOpen: () => {
        // @ts-ignore
        setIsOpen((prevState) => {
          if (!prevState) {
            return { [`${index}`]: true };
          }
          // @ts-ignore
          return { ...prevState, [`${index}`]: !prevState[`${index}`] };
        });
      },
    };
  }

  useEffect(() => {
    if (schema && schema?.length > 0) {
      setList(schema?.map(mapList));
    }
  }, [schema]);

  return (
    <div>
      {list &&
        list.length > 0 &&
        list?.map((extractionSchema, index) => {
          return (
            <Accordion
              key={extractionSchema.id}
              defaultChecked={false}
              // @ts-ignore
              open={isOpen ? isOpen[index] : false}
              // @ts-ignore
              icon={<ChevronDown isOpen={isOpen ? isOpen[index] : false} />}
            >
              <AccordionHeader onClick={extractionSchema.handleOpen}>
                <div className="flex w-full flex-row justify-between">
                  <Typography variant="paragraph">
                    {" "}
                    {extractionSchema.name}
                  </Typography>
                  {setSelectedSchema && (
                    <Button
                      ripple={true}
                      className="items-center p-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedSchema(extractionSchema);
                      }}
                    >
                      {children}
                    </Button>
                  )}
                </div>
              </AccordionHeader>
              <AccordionBody className="pt-0 text-base font-normal">
                <div>
                  <EditExtractionSchema extractionSchema={extractionSchema} />
                </div>
              </AccordionBody>
            </Accordion>
          );
        })}
    </div>
  );
}

export default ExtractionSchemaList;
