export function MemoryCardIcon({ className }: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
    >
      <path d="M5,10.7071068 L4.14644661,9.85355339 C4.05267842,9.7597852 4,9.63260824 4,9.5 L4,2.5 C4,2.22385763 4.22385763,2 4.5,2 L14.5,2 C14.6326082,2 14.7597852,2.05267842 14.8535534,2.14644661 L18.8535534,6.14644661 C18.9473216,6.2402148 19,6.36739176 19,6.5 L19,19.5 C19,20.8807119 17.8807119,22 16.5,22 L6.5,22 C5.11928813,22 4,20.8807119 4,19.5 L4,13.5 C4,13.3673918 4.05267842,13.2402148 4.14644661,13.1464466 L5,12.2928932 L5,10.7071068 Z M5,3 L5,9.29289322 L5.85355339,10.1464466 C5.94732158,10.2402148 6,10.3673918 6,10.5 L6,12.5 C6,12.6326082 5.94732158,12.7597852 5.85355339,12.8535534 L5,13.7071068 L5,19.5 C5,20.3284271 5.67157288,21 6.5,21 L16.5,21 C17.3284271,21 18,20.3284271 18,19.5 L18,6.70710678 L14.2928932,3 L5,3 Z M9.5,19 C9.10065231,19 8.86245661,18.5549273 9.08397485,18.2226499 L11.0839749,15.2226499 C11.2818859,14.9257834 11.7181141,14.9257834 11.9160251,15.2226499 L13.9160251,18.2226499 C14.1375434,18.5549273 13.8993477,19 13.5,19 L9.5,19 Z M12.5657415,18 L11.5,16.4013878 L10.4342585,18 L12.5657415,18 Z M6,4.5 C6,4.22385763 6.22385763,4 6.5,4 C6.77614237,4 7,4.22385763 7,4.5 L7,7.5 C7,7.77614237 6.77614237,8 6.5,8 C6.22385763,8 6,7.77614237 6,7.5 L6,4.5 Z M8,4.5 C8,4.22385763 8.22385763,4 8.5,4 C8.77614237,4 9,4.22385763 9,4.5 L9,7.5 C9,7.77614237 8.77614237,8 8.5,8 C8.22385763,8 8,7.77614237 8,7.5 L8,4.5 Z M10,4.5 C10,4.22385763 10.2238576,4 10.5,4 C10.7761424,4 11,4.22385763 11,4.5 L11,7.5 C11,7.77614237 10.7761424,8 10.5,8 C10.2238576,8 10,7.77614237 10,7.5 L10,4.5 Z M12,4.5 C12,4.22385763 12.2238576,4 12.5,4 C12.7761424,4 13,4.22385763 13,4.5 L13,7.5 C13,7.77614237 12.7761424,8 12.5,8 C12.2238576,8 12,7.77614237 12,7.5 L12,4.5 Z M14,5.5 C14,5.22385763 14.2238576,5 14.5,5 C14.7761424,5 15,5.22385763 15,5.5 L15,7.5 C15,7.77614237 14.7761424,8 14.5,8 C14.2238576,8 14,7.77614237 14,7.5 L14,5.5 Z" />
    </svg>
  );
}
