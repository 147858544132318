import clsx from "clsx";
import { LeadAgentRow } from "../../api/leadAgent.type";
import { formatFullDateToShortDate } from "../../utils/parseTimeStamp";
import { getFirstTwoLettersFromSentence } from "../../utils/stringManipulation";

function LcNavItem({
  lc,
  isActive = false,
}: {
  lc: LeadAgentRow;
  isActive: boolean;
}) {
  return (
    <a
      href={`/a/${lc?.agent_id}/t/${lc?.id}`}
      className={clsx(
        isActive ? "text-skin-accent" : "text-skin-base hover:bg-skin-hover",
        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
      )}
    >
      <span
        className={clsx(
          isActive
            ? "border-skin-accent text-skin-accent"
            : "border-skin-muted text-skin-base group-hover:border-indigo-600",
          "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-skin-fill text-[0.625rem] font-medium capitalize",
        )}
      >
        {getFirstTwoLettersFromSentence(lc?.lead?.first_name || "")}
      </span>
      <span className="truncate">
        {lc.lead?.first_name &&
          lc.lead.first_name + " " + lc.lead.last_name + " - "}
        {formatFullDateToShortDate(lc?.created_at)}
      </span>
    </a>
  );
}

export default LcNavItem;
