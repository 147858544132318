import { Button, ButtonProps } from "@material-tailwind/react";

import { PlusIcon } from "../icons/icons";

function AddButton({
  onClick,
  iconClass,
  children,
}: {
  onClick: () => void;
  iconClass: string;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLButtonElement> &
  ButtonProps) {
  return (
    <Button
      variant="outlined"
      className="flex items-center gap-2 p-2"
      onClick={onClick}
      ripple={true}
    >
      <PlusIcon className={iconClass} />
      {children}
    </Button>
  );
}

export default AddButton;
