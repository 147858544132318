import AgentSettingsContent from "../agent/settings/AgentSettingsContent";
import AgentSettingsHeader from "../agent/settings/AgentSettingsHeader";

function AgentSettingsMenu({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="sticky bottom-0 top-0 flex h-screen min-w-96 max-w-xl flex-col overflow-y-auto bg-skin-fill pt-6 shadow-lg">
      <AgentSettingsHeader setOpen={setOpen} />
      <AgentSettingsContent />
    </div>
  );
}

export default AgentSettingsMenu;
