import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import {
  LeadInsert,
  LeadRow,
  LeadWithRelations,
  LeadsWithPageAndCount,
} from "../api/lead.type";
import { BulkConfirmationRequest } from "./location.type";

export function getLinkedLeads(): Promise<LeadRow[]> {
  const url = process.env.REACT_APP_API_URL + "/lead/linked";

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<LeadRow[]>(url, request);
}

export function createWebhookTest({
  agentId,
  phone,
}: {
  agentId: string;
  phone: string;
}): Promise<LeadRow> {
  const url = process.env.REACT_APP_API_URL + `/lead/klaviyo/${agentId}`;

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ phone, is_test: true }),
  };

  return fetchData<LeadRow>(url, request);
}

export function handleSignupUser({
  userId,
  companyId,
  leadId,
}: {
  userId: string;
  companyId: string;
  leadId: string;
}): Promise<LeadWithRelations> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.user + "/signup";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
      companyId,
      leadId,
    }),
  };

  return fetchData<LeadWithRelations>(url, request);
}

export function updateLeadById({
  id,
  values,
}: {
  id: string;
  values: LeadInsert;
}): Promise<LeadWithRelations> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/fabrk/" + id;

  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...values,
    }),
  };

  return fetchData<LeadWithRelations>(url, request);
}

export function getLeadById(id: string): Promise<LeadWithRelations> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/fabrk/" + id;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<LeadWithRelations>(url, request);
}

export function getLeadByUserId(userId: string): Promise<LeadWithRelations> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/user/" + userId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<LeadWithRelations>(url, request);
}

export function getTestLeadsByCampaign(agentId: string): Promise<LeadRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/test/" + agentId;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<LeadRow[]>(url, request);
}

export function searchLeads(param: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.lead +
    "/search/" +
    encodeURIComponent(param);

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<LeadsWithPageAndCount>(url, request);
}

export function addBulkLeads(data: BulkConfirmationRequest) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.lead + "/bulk";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(data),
  });

  return fetchData<LeadRow[]>(url, request);
}
