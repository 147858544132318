import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import {
  CreateSmsMessage,
  SendFabrkSmsRequest,
  SmsMessage,
} from "../api/sms.type";

export function getSms(): Promise<SmsMessage[]> {
  const url = process.env.REACT_APP_API_URL + "/sms";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<SmsMessage[]>(url, request);
}

export function getSmsCounts(): Promise<number> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.sms + "/count";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<number>(url, request);
}

export function getSmsMessagesByLeadCampaign({
  leadAgentId,
}: {
  leadAgentId: string;
}): Promise<SmsMessage[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.sms +
    "/leadAgent/" +
    leadAgentId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData(url, request);
}

export function getSmsMessagesByLeadForDemo({
  leadId,
}: {
  leadId: string;
}): Promise<SmsMessage[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.sms +
    "/demo/lead-id/" +
    leadId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<SmsMessage[]>(url, request);
}

export function getNewSmsMessagesByLeadForFabrk({
  leadId,
  agentId,
  createdTime,
}: {
  leadId: string;
  agentId: string;
  createdTime: string | null | undefined;
}): Promise<SmsMessage> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.sms + "/fabrk/new";

  const request = {
    method: "POST",
    body: JSON.stringify({ agentId, leadId, createdTime }),
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<SmsMessage>(url, request);
}

export function createFabrkMessage(values: CreateSmsMessage): Promise<any> {
  const url = process.env.REACT_APP_API_URL + "/sms/create";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ ...values }),
  };

  return fetchData(request.url, request);
}

export function sendFabrkSmsOrEmail(values: SendFabrkSmsRequest): Promise<any> {
  const url = process.env.REACT_APP_API_URL + "/sms/fabrk/send";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ ...values }),
  };

  return fetch(request.url, request);
}
