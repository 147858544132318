import clsx from "clsx";
import { AgentRow } from "../../api/agent.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { getFirstTwoLettersFromSentence } from "../../utils/stringManipulation";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

function ThreadNavItem({
  a,
  isActive = false,
  toggle,
  isOpen,
}: {
  a: AgentRow;
  isActive: boolean;
  toggle?: () => void;
  isOpen?: boolean;
}) {
  return (
    <div className="flex w-full flex-row items-center justify-between">
      <a
        href={`/a/${a.id}`}
        className={clsx(
          isActive ? "text-skin-accent" : "text-skin-base hover:bg-skin-hover",
          "group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
        )}
      >
        <span
          className={clsx(
            isActive
              ? "border-skin-accent text-skin-accent"
              : "border-skin-muted text-skin-base group-hover:border-indigo-600",
            "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-skin-fill text-[0.625rem] font-medium capitalize",
          )}
        >
          {getFirstTwoLettersFromSentence(a.name)}
        </span>
        <span className="truncate">
          {a.name || formatTimeStampToHumanReadableShortDateTime(a?.created_at)}
        </span>
      </a>
      {a && a.lead_agent && a?.lead_agent?.length > 0 && (
        <button onClick={toggle}>
          {isOpen ? (
            <ChevronDownIcon className="h-6 w-6 rounded-full p-1 text-skin-muted hover:bg-skin-hover" />
          ) : (
            <ChevronUpIcon className="h-6 w-6 rounded-full p-1 text-skin-muted" />
          )}
        </button>
      )}
    </div>
  );
}

export default ThreadNavItem;
