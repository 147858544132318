import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
  DialogBackdrop,
} from "@headlessui/react";

import { Typography } from "@material-tailwind/react";
import { Fragment } from "react";
import FabrkMenuDivider from "../../fabrk/layout/FabrkMenuDivider";

function GenericDialog({
  open,
  setOpen,
  title,
  children,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Fragment>
      {open && (
        <Transition appear show={open} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => setOpen(false)}
          >
            <DialogBackdrop
              transition
              className="fixed inset-0 bg-skin-muted/10 bg-opacity-75 transition-opacity"
            />

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <DialogPanel className="border-1 w-full max-w-md transform rounded-2xl bg-skin-fill p-6 text-left align-middle text-skin-base shadow-lg transition-all">
                    <Typography className="font-bold">{title}</Typography>
                    <FabrkMenuDivider />
                    {children}
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </Fragment>
  );
}

export default GenericDialog;
