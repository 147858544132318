import CloseButton from "./CloseButton";

function ToolsContainerHeader({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="pb-1 sm:pb-6">
      <div className="flex w-full flex-row justify-between px-6">
        <h3 className="text-xl font-bold text-skin-base sm:text-2xl">Tools</h3>
        <CloseButton setOpen={setOpen} />
      </div>

      <div className="p-6">
        <dd className="mt-1 text-sm text-skin-base sm:col-span-2">
          <p className="text-wrap">
            These are the tools available for you to use. Tools give your agents
            the ability to perform tasks and actions. You can add or remove
            tools as needed.
          </p>
        </dd>
      </div>
    </div>
  );
}

export default ToolsContainerHeader;
