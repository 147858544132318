import LandingPageTitle from "../layout/LandingPageTitle";
import LayoutContainer from "../layout/LayoutContainer";
import ActionCard from "./ActionCard";
import { useLoadActions } from "./hooks/useLoadActions";

export default function ActionGridContainer() {
  const { actions } = useLoadActions();
  return (
    <LayoutContainer>
      <div className="bg-skin-fill py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <LandingPageTitle />
        </div>
        <div className="bg-fill-muted">
          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
            <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
              {actions?.map((action) => (
                <ActionCard key={action.id} action={action} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </LayoutContainer>
  );
}
