import { FileUploadContextWrapper } from "../hooks/fileUploadContext";
import FileUploadedIndicator from "./FileUploadedIndicator";
import TextAreaToolBadge from "./TextAreaToolBadge";
import TextAreaWithButtons from "./TextAreaWithButtons";

function TextAreaContainer({
  handleToggle,
}: {
  handleToggle: (type: "tools" | "settings") => void;
}) {
  return (
    <FileUploadContextWrapper>
      <div className="sticky bottom-1 top-0 mx-auto flex w-full flex-col items-center justify-center gap-4">
        <FileUploadedIndicator />
        <TextAreaWithButtons />
        <TextAreaToolBadge
          toggleTools={() => handleToggle("tools")}
          toggleSettings={() => handleToggle("settings")}
        />
      </div>
    </FileUploadContextWrapper>
  );
}

export default TextAreaContainer;
