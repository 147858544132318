const randomAnimals = [
  "alligator",
  "anteater",
  "armadillo",
  "auroch",
  "axolotl",
  "badger",
  "bat",
  "beaver",
  "buffalo",
  "camel",
  "chameleon",
  "cheetah",
  "chipmunk",
  "chinchilla",
  "chupacabra",
  "cormorant",
  "coyote",
  "crow",
  "dingo",
  "dinosaur",
  "dog",
  "dolphin",
  "duck",
  "elephant",
  "ferret",
  "fox",
  "frog",
  "giraffe",
  "gopher",
  "grizzly",
  "hedgehog",
  "hippo",
  "hyena",
  "jackal",
  "ibex",
  "ifrit",
  "iguana",
  "koala",
  "kraken",
  "lemur",
  "leopard",
  "liger",
];

const randomAdjectives = [
  "autumn",
  "hidden",
  "bitter",
  "misty",
  "silent",
  "empty",
  "dry",
  "dark",
  "summer",
  "icy",
  "delicate",
  "quiet",
  "cool",
  "spring",
  "winter",
  "patient",
  "twilight",
  "dawn",
  "wispy",
  "weathered",

  "billowing",
  "broken",
  "cold",
  "damp",
  "falling",
  "frosty",

  "long",
  "late",
  "lingering",
  "bold",
  "little",
  "morning",
  "muddy",
  "old",

  "rough",
  "still",
  "small",
  "sparkling",
  "throbbing",
  "shy",
  "wandering",
  "withered",
  "wild",
  "young",
  "holy",
  "solitary",
  "fragrant",
  "aged",
  "snowy",
  "proud",
  "floral",
  "restless",
  "divine",
  "polished",
  "ancient",
  "lively",
  "nameless",
];

const randomColors = [
  "red",
  "orange",
  "yellow",
  "green",
  "blue",
  "purple",
  "pink",
  "brown",
  "black",
  "white",
  "gray",
];

export function randomName(): string {
  const randomAnimal =
    randomAnimals[Math.floor(Math.random() * randomAnimals.length)];
  const randomAdjective =
    randomAdjectives[Math.floor(Math.random() * randomAdjectives.length)];
  const randomColor =
    randomColors[Math.floor(Math.random() * randomColors.length)];

  return `${randomAdjective} ${randomColor} ${randomAnimal}`;
}
