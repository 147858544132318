import { ActionInsert, ActionRow } from "./action.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";

export function createAction(
  action: Partial<ActionInsert> | null,
): Promise<ActionRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.action;

  const request = {
    method: "POST",
    body: JSON.stringify(action),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchData<ActionRow>(url, request);
}

export function getPublicActions(): Promise<ActionRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.action;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ActionRow[]>(url, request);
}

export function getActionById(id: string): Promise<ActionRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.action + `/${id}`;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ActionRow>(url, request);
}
