import { Typography } from "@material-tailwind/react";
import { useDarkModeContext } from "../fabrk/layout/hooks/useDarkMode";

function LogoWithTitle() {
  const { isDarkMode } = useDarkModeContext();

  return (
    <div className="flex h-16 shrink-0 items-center gap-4">
      {isDarkMode ? (
        <img
          className="h-12 w-12 rounded-full bg-skin-fill"
          src="https://ilmvwgbjkutsjcydbiez.supabase.co/storage/v1/object/public/test-images/white_text_black_background_logo.png"
          alt="Fabrk AI Logo"
        />
      ) : (
        <img
          className="h-12 w-12 rounded-full bg-skin-fill"
          src="/fabrk-black.png"
          alt="Fabrk AI Logo"
        />
      )}
      <Typography className="text-lg font-bold text-skin-base">
        Fabrk AI
      </Typography>
    </div>
  );
}

export default LogoWithTitle;
