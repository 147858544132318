import { useState } from "react";
import { useLeadAgentContext } from "../fabrk/hooks/LeadAgentContext";
import FabrkMenuDivider from "../fabrk/layout/FabrkMenuDivider";
import IconButton from "../shared/buttons/IconButton";
import { EditIcon } from "../shared/icons/icons";
import EditToolName from "./EditToolName";
import { useToolContext } from "./hooks/ToolContext";

function ViewToolHeader() {
  const [isEdit, setIsEdit] = useState(false);
  const { tool } = useToolContext();
  const { leadAgents } = useLeadAgentContext();

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div className="lg:pr-4">
          <div className="relative overflow-hidden rounded-3xl bg-skin-base px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
            <img
              alt=""
              src={tool?.image_path || "/images/robot.webp"}
              className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
            />

            <figure className="relative isolate">
              <figcaption className="mt-6 w-fit rounded-md bg-skin-hover px-4 leading-6">
                <strong className="text-xl font-bold text-skin-accent">
                  {tool?.name}
                </strong>{" "}
              </figcaption>
            </figure>
          </div>
        </div>
        <div>
          <div className="w-full leading-7 text-skin-base/80">
            <div className="flex flex-row justify-between">
              <p className="font-semibold leading-7 text-skin-muted/80">
                Tool Details
              </p>
              <IconButton
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
              >
                <EditIcon className="h-5 w-5" />
              </IconButton>
            </div>
            <FabrkMenuDivider />
            {isEdit ? (
              <EditToolName setIsEdit={setIsEdit} />
            ) : (
              <div>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-skin-base sm:text-4xl">
                  {tool?.name}
                </h1>
                <div className="max-w-xl">
                  <p className="mt-6">{tool?.description}</p>
                </div>
              </div>
            )}
          </div>
          <dl className="mt-10 grid grid-cols-2 gap-8 pt-10 sm:grid-cols-2">
            <div>
              <dt className="text-sm font-semibold leading-6 text-skin-base">
                Agents using this tool
              </dt>
              <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-skin-base">
                {tool?.agent_tool?.length || 0}
              </dd>
            </div>
            <div className="mt-10 flex flex-col gap-2">
              {tool?.agent_tool?.map((agent) => {
                const leadAgent = leadAgents?.find(
                  (lc) => lc.agent_id === agent.agent_id,
                );

                if (!leadAgent) {
                  return null;
                }

                return (
                  <a
                    href={`/a/${leadAgent?.agent_id}/t/${leadAgent?.id}`}
                    key={agent.id}
                  >
                    <div className="flex h-fit flex-row items-center justify-evenly gap-1 rounded-md bg-skin-hover p-1 text-skin-base">
                      <p className="text-xs">{leadAgent?.agent?.name}</p>
                    </div>
                  </a>
                );
              })}
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default ViewToolHeader;
