import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { Cog6ToothIcon, MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { useDarkModeContext } from "../fabrk/layout/hooks/useDarkMode";
import { useCompanyContext } from "../fabrk/hooks/CompanyContext";

export default function SettingsMenu() {
  const { isDarkMode, toggleDarkMode } = useDarkModeContext();

  const { company } = useCompanyContext();

  return (
    <Menu as="div" className="relative z-10">
      <MenuButton className="justify-left flex w-full gap-4 rounded-md bg-skin-fill px-3 py-2 text-left text-sm font-semibold text-skin-base hover:bg-skin-hover">
        <Cog6ToothIcon
          aria-hidden="true"
          className="h-6 w-6 shrink-0 text-skin-base group-hover:bg-skin-accent"
        />
        Settings
      </MenuButton>

      <MenuItems
        transition
        className="absolute -top-24 left-0 z-10 mt-2 w-56 origin-top-right rounded-md border-2 border-skin-hover bg-skin-fill shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          {company?.name && (
            <MenuItem>
              <p className="block px-4 py-2 text-sm text-skin-base/80">
                Project - {company?.name}
              </p>
            </MenuItem>
          )}
          <MenuItem>
            <button
              onClick={toggleDarkMode}
              className="group flex w-full items-center bg-skin-fill px-4 py-2 text-sm text-skin-base data-[focus]:bg-skin-hover data-[focus]:text-skin-base"
            >
              {isDarkMode ? (
                <MoonIcon className="mr-3 h-5 w-5 text-skin-base group-hover:bg-skin-hover" />
              ) : (
                <SunIcon
                  aria-hidden="true"
                  className="mr-3 h-5 w-5 text-skin-base group-hover:bg-skin-hover"
                />
              )}
              {isDarkMode ? "Dark" : "Light"} Mode
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
}
