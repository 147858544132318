import {
  CogIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { List, UserGroup } from "../shared/icons/icons";

export type NavigationItem = {
  name: string;
  href: string;
  icon: any;
};

export type TeamItem = {
  id: number;
  name: string;
  href: string;
  initial: string;
};

export type UserNavigationItem = {
  name: string;
  href: string;
};

export const chatIcons = [
  { name: "settings", icon: CogIcon },
  {
    name: "tools",
    icon: WrenchIcon,
  },
  {
    name: "meta",
    icon: List,
  },
];

export const navigation = [
  { name: "Home", href: "/", icon: HomeIcon },
  {
    name: "Agents",
    href: "/agents",
    icon: UserGroup,
  },
  {
    name: "Tools",
    href: "/tools",
    icon: WrenchIcon,
  },
  {
    name: "Discover",
    href: "/discover/agents",
    icon: MagnifyingGlassIcon,
  },
];

export const userNavigation = [{ name: "Sign out", href: "#" }];
