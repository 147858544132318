import { useQuery } from "react-query";
import { getAvailableTools } from "../../../api/companyTool.api";

export function useLoadUsersTools(companyId?: string) {
  const {
    data: usersTools,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: "usersTools",
    queryFn: () => getAvailableTools(companyId),
  });

  return {
    isLoading,
    usersTools,
    error,
    refetch,
  };
}
