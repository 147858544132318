import { Dialog, DialogPanel } from "@headlessui/react";
import MenuContainer from "./MenuContainer";

function ToolsDialogMenu({
  open,
  setOpen,
  menuType,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  menuType: string;
}) {
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <MenuContainer menuType={menuType} setOpen={setOpen} />
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ToolsDialogMenu;
