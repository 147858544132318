import clsx from "clsx";

function NavItem({
  current,
  item,
}: {
  current: string;
  item: {
    name: string;
    href: string;
    icon: any;
  };
}) {
  return (
    <a
      href={item.href}
      className={clsx(
        item.href === current
          ? "bg-skin-hover text-skin-accent"
          : "text-skin-base hover:bg-skin-hover",
        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
      )}
    >
      <item.icon
        aria-hidden="true"
        className={clsx(
          item.href === current
            ? "text-skin-accent"
            : "text-skin-base group-hover:bg-skin-hover",
          "h-6 w-6 shrink-0",
        )}
      />
      {item.name}
    </a>
  );
}

export default NavItem;
