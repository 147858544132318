import { useFieldArray, UseFormRegister } from "react-hook-form";
import { randomName } from "../../utils/randomNames";
import IconButton from "../shared/buttons/IconButton";
import { PlusIcon } from "../shared/icons/icons";
import EditParameterItem from "./EditParameterItem";
import { ParameterFormValues } from "./hooks/createParameterObjectHelper";

const NestedPropertiesFieldArray = ({
  control,
  index,
  register,
  watch,
  errors,
}: {
  control: any;
  index: number;
  register: UseFormRegister<ParameterFormValues>;
  watch: any;
  errors: any;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `parameters.${index}.additionalProperties`,
  });

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold">Nested Properties</h3>
      {fields.map((item, nestedIndex) => {
        return (
          <EditParameterItem
            nestedName={`parameters.${index}.additionalProperties`}
            item={item}
            index={nestedIndex}
            watch={watch}
            remove={remove}
            control={control}
            register={register}
            errors={errors}
          />
        );
      })}

      <div className="max-w-md">
        <IconButton
          onClick={() =>
            append({
              name: randomName(),
              type: "string",
              description: "",
              default: "",
              additionalProperties: [],
            })
          }
        >
          <PlusIcon className="mr-2 h-5 w-5" />
          Add Parameter
        </IconButton>
      </div>
    </div>
  );
};

export default NestedPropertiesFieldArray;
