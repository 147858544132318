import { formatTimeStampToHumanReadableShortDateTime } from "../../../utils/parseTimeStamp";

function DateDetailsDetails({ date, title }: { date: string; title: string }) {
  return (
    <div>
      <p className="text-xs text-skin-base/80">{title}</p>
      <p className="text-slate-700">
        {formatTimeStampToHumanReadableShortDateTime(date)}
      </p>
    </div>
  );
}

export default DateDetailsDetails;
