import { Typography } from "@material-tailwind/react";
import { SmsMetadataRow } from "../../../../api/smsMetadata.type";
import ToolCalledMetadataItem from "./ToolCalledMetadataItem";
import { useState } from "react";
import { safeJsonStringify } from "../../../../utils/objectManipulation";

function ToolCalledMetadataContainer({
  toolMessages,
}: {
  toolMessages?: SmsMetadataRow[];
}) {
  const [activeToolId, setActiveToolId] = useState<string | null>(null);

  const [view, setView] = useState<string>("base");

  return (
    <div
      className={
        activeToolId
          ? "min-w-md flex flex-col items-start gap-4"
          : "min-w-md grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
      }
    >
      {toolMessages
        ?.filter((msg) => (activeToolId ? msg.id === activeToolId : true))
        .map((msg, idx) => {
          const input = safeJsonStringify("No input", msg.function_call_value);

          const output = safeJsonStringify(
            "No output",
            msg.function_call_response,
          );

          return (
            <div>
              <ToolCalledMetadataItem
                msg={msg}
                idx={idx}
                setActiveToolId={setActiveToolId}
                setView={setView}
                view={view}
                activeToolId={activeToolId}
              />
              <div className="m-1 p-4">
                {view === "input" && msg.function_call_value && (
                  <pre className="break-word whitespace-pre-wrap p-4">
                    {input}
                  </pre>
                )}
                {view === "output" && (
                  <pre className="break-word whitespace-pre-wrap p-4">
                    {output}
                  </pre>
                )}
              </div>
            </div>
          );
        })}
      {toolMessages?.length === 0 && (
        <Typography>No tool calls made</Typography>
      )}
    </div>
  );
}

export default ToolCalledMetadataContainer;
