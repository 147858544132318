import React, { useState } from "react";
import { LeadAgentRow } from "../../api/leadAgent.type";
import { useLeadAgentContext } from "../fabrk/hooks/LeadAgentContext";
import LcNavItem from "./LcNavItem";

interface LeadCampaignListProps {
  leadAgents: LeadAgentRow[] | undefined;
}

function LcList({ leadAgents }: LeadCampaignListProps) {
  const { activeLeadAgent } = useLeadAgentContext();

  // State to manage how many items to display
  const [visibleCount, setVisibleCount] = useState(5);

  // If no agents are available, return nothing
  if (!leadAgents || leadAgents.length === 0) return null;

  const showMoreAmount = 10;

  // Function to show more items
  const showMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + showMoreAmount);
  };

  // Slicing the array to show only the number of visible items
  const visibleCampaigns = leadAgents.slice(0, visibleCount);

  return (
    <>
      <ul className="ml-2">
        {activeLeadAgent && (
          <li>
            <LcNavItem lc={activeLeadAgent} isActive={true} />
          </li>
        )}
        {visibleCampaigns
          .filter((lc) => lc.id !== activeLeadAgent?.id)
          .map((lc: LeadAgentRow) => {
            return (
              <li key={lc.id}>
                <LcNavItem lc={lc} isActive={lc.id === activeLeadAgent?.id} />
              </li>
            );
          })}

        {visibleCount < leadAgents.length && (
          <button
            onClick={showMoreItems}
            className="ml-3 mt-2 text-xs text-skin-accent hover:underline"
          >
            Show {showMoreAmount} More
          </button>
        )}
      </ul>
    </>
  );
}

export default LcList;
