import { Schema } from "../../api/extractionSchema.type";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";
import { DataValueBase, LabelBase } from "../shared/typography/TypographyBase";

function ViewSchema({ schema }: { schema: Schema[] }) {
  return (
    <div className="flex w-full flex-col gap-5 border-b border-blue-gray-100 p-5">
      {schema?.map((item, index) => {
        const { type, question, id, required } = item;
        return (
          <div
            key={index}
            className="grid gap-2 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-6"
          >
            <div>
              <LabelBase>Id</LabelBase>
              <DataValueBase>{id || "N/A"}</DataValueBase>
            </div>

            <div>
              <LabelBase>Required</LabelBase>
              <DataValueBase>{required ? "Yes" : "No"}</DataValueBase>
            </div>

            <div>
              <LabelBase>Type</LabelBase>
              <DataValueBase>
                {snakeCaseToTitleCase(
                  //@ts-ignore
                  type === "boolean" ? "yes/no" : type,
                )}
              </DataValueBase>
            </div>

            <div className="col-span-3">
              <LabelBase>Question</LabelBase>
              <DataValueBase>{question}</DataValueBase>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ViewSchema;
