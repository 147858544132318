/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation } from "react-query";

import { useSearchParams } from "react-router-dom";
import { getActionById } from "../../../api/action.api";
import { AgentRow } from "../../../api/agent.type";
import {
  createFabrkMessage,
  getSmsMessagesByLeadCampaign,
  sendFabrkSmsOrEmail,
} from "../../../api/sms.api";
import { SendFabrkSmsRequest, SmsMessage } from "../../../api/sms.type";
import { useEventStreaming } from "../../message/StreamMessageContext";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useLeadAgentContext } from "./LeadAgentContext";

export const MessageContextWrapper = (props: any) => {
  const { activeLeadAgent } = useLeadAgentContext();

  const [counter, setCounter] = useState(0);
  const [persona, setPersona] = useState<AgentRow | undefined>();

  const { setAlertProps } = useAlertContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const [threadId, setThreadId] = useState<string | undefined>();

  const [newThread, setNewThread] = useState(false);

  const [loading, setLoading] = useState(false);

  const [messages, setMessages] = useState<SmsMessage[]>([]);
  const [newMessage, setNewMessage] = useState<SmsMessage | undefined>();

  const [messageCreatedTime, setMessageCreatedTime] = useState<string | null>();

  const { setText } = useEventStreaming();

  const {
    mutate: getAction,
    reset: resetAction,
    data: action,
  } = useMutation(getActionById);

  const { mutate: createMessage, reset } = useMutation(createFabrkMessage, {
    onSuccess: async (res) => {
      setNewMessage(res);
      if (messages) {
        setMessages((prev) => [...prev, res]);
      } else {
        setMessages([res]);
      }
    },
  });

  function handleCreateMessage({
    content,
    fileStoreId,
    actionId,
  }: {
    content: string;
    fileStoreId?: string;
    actionId?: string;
  }) {
    setLoading(true);
    if (!activeLeadAgent) {
      setAlertProps({
        message: "No active agent",
        color: "red",
      });
      setLoading(false);
      return;
    }

    createMessage({
      agentId: activeLeadAgent.agent_id,
      companyId: activeLeadAgent.company_id,
      leadId: activeLeadAgent.lead_id,
      content,
      source: "fabrk",
      ...(threadId && !newThread && { threadId }),
      ...(fileStoreId && { fileStoreId }),
      ...(actionId && { actionId }),
    });
  }

  const { mutate: getMessages } = useMutation(getSmsMessagesByLeadCampaign, {
    onSuccess: async (res) => {
      setMessages(res);
      setText("");
    },
    onError(error: Error) {
      console.log(error);
    },
    onSettled: () => {
      setLoading(false);
      setMessageCreatedTime(null);
    },
  });

  const { mutate: sendMessage } = useMutation(sendFabrkSmsOrEmail, {
    onSuccess: async (res) => {
      setAlertProps({
        message: "Message sent. You will receive a message response shortly.",
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error);
      setAlertProps({
        message: "Failed to send message" + error.message,
        color: "red",
      });
    },
  });

  function getUpdatedMessages() {
    if (activeLeadAgent) {
      getMessages({ leadAgentId: activeLeadAgent.id });
    }
  }

  useEffect(() => {
    getUpdatedMessages();
  }, [activeLeadAgent]);

  const actionId = searchParams.get("action_id");

  useEffect(() => {
    if (actionId) {
      getAction(actionId);
    }
  }, [actionId]);

  useEffect(() => {
    if (action) {
      if (action?.content) {
        handleCreateMessage({ content: action?.content, actionId: action?.id });
      } else if (action?.sms_message?.content) {
        handleCreateMessage({
          content: action?.sms_message?.content,
          actionId: action?.id,
        });
      }
      setSearchParams((prev) => {
        prev?.delete("action_id");
        return prev;
      });
      resetAction();
    }
  }, [action]);

  const value = {
    messages,
    handleCreateMessage,
    loading,
    setMessages,
    setNewThread,
    setThreadId,
    threadId,
    sendMessage,
    newMessage,
    messageCreatedTime,
    reset,
    getUpdatedMessages,
    setNewMessage,
    setCounter,
    counter,
    persona,
    setPersona,
  };

  return (
    <MessageContext.Provider value={value}>
      {props.children}
    </MessageContext.Provider>
  );
};

export const MessageContext = createContext({
  messages: {} as SmsMessage[] | undefined,
  handleCreateMessage: {} as ({
    content,
    fileStoreId,
    actionId,
  }: {
    content: string;
    fileStoreId?: string;
    actionId?: string;
  }) => void,
  loading: false,
  setMessages: {} as React.Dispatch<React.SetStateAction<SmsMessage[]>>,
  setNewThread: {} as React.Dispatch<React.SetStateAction<boolean>>,
  setThreadId: {} as React.Dispatch<React.SetStateAction<string | undefined>>,
  threadId: "" as string | undefined,
  sendMessage: {} as UseMutateFunction<
    any,
    Error,
    SendFabrkSmsRequest,
    unknown
  >,
  messageCreatedTime: "" as string | null | undefined,
  newMessage: {} as SmsMessage | undefined,
  reset: {} as () => void,
  getUpdatedMessages: {} as () => void,
  setNewMessage: {} as React.Dispatch<
    React.SetStateAction<SmsMessage | undefined>
  >,
  setCounter: {} as React.Dispatch<React.SetStateAction<number>>,
  counter: 0,
  persona: {} as AgentRow | undefined,
  setPersona: {} as React.Dispatch<React.SetStateAction<AgentRow | undefined>>,
});

export const useMessageContext = () => useContext(MessageContext);
