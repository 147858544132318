/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation } from "react-query";

import { useParams } from "react-router-dom";
import { getToolById, updateToolById } from "../../../api/tool.api";
import { ToolRow, ToolUpdate } from "../../../api/tool.type";
import { useAlertContext } from "../../shared/alert/alertContext";

export const ToolContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();

  const [selectedTool, setSelectedTool] = useState<ToolRow | undefined>();

  const { id } = useParams();

  const { mutate: getTool, data: tool } = useMutation(getToolById);

  const { mutate: updateTool } = useMutation(updateToolById, {
    onSuccess: async (res) => {
      getTool(id as string);
      setAlertProps({
        message: "Tool updated",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  function handleGetTool() {
    if (id) {
      getTool(id);
    }
  }

  useEffect(() => {
    if (!tool) {
      handleGetTool();
    }
  }, [id]);

  const value = {
    tool,
    updateTool,
    handleGetTool,
    selectedTool,
    setSelectedTool,
  };

  return (
    <toolContext.Provider value={value}>{props.children}</toolContext.Provider>
  );
};

export const toolContext = createContext({
  tool: {} as ToolRow | undefined,
  updateTool: {} as UseMutateFunction<ToolRow, Error, ToolUpdate, unknown>,
  handleGetTool: {} as () => void,
  selectedTool: {} as ToolRow | undefined,
  setSelectedTool: {} as (tool: ToolRow) => void,
});

export const useToolContext = () => useContext(toolContext);
