import { ToolRow, ToolUpdate } from "./tool.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";

export function getPublicTools(): Promise<ToolRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.tool;

  const request = { method: "GET" };

  return fetchData<ToolRow[]>(url, request);
}

export function getToolBySlug(slug: string): Promise<ToolRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.tool + `/slug/${slug}`;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ToolRow>(url, request);
}

export function getToolById(id: string): Promise<ToolRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.tool + `/${id}`;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ToolRow>(url, request);
}

export function updateToolById(tool: ToolUpdate): Promise<ToolRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.tool + `/${tool.id}`;

  const request = {
    method: "PUT",
    body: JSON.stringify(tool),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ToolRow>(url, request);
}
