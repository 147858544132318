import { useQuery } from "react-query";
import { getCompanyAgents } from "../../../api/agent.api";
import { useSessionContext } from "../../fabrk/hooks/FabrkSessionContext";

export function useLoadCompanyAgents() {
  const { companyId } = useSessionContext();

  const {
    data: companyAgents,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: "companyAgents",
    queryFn: () => getCompanyAgents(companyId),
    enabled: !!companyId,
  });

  return {
    isLoading,
    companyAgents,
    error,
    refetch,
  };
}
