import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import {
  AgentDraftType,
  AgentInsert,
  AgentRow,
  AgentUpdate,
} from "./agent.type";
import { LeadAgentRow } from "./leadAgent.type";

export function getAgents(): Promise<AgentRow[]> {
  const url = process.env.REACT_APP_API_URL + "/Agent";

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<AgentRow[]>(url, request);
}

export function getDemoAgents(): Promise<AgentRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.agent + "/public";

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<AgentRow[]>(url, request);
}

export function getCompanyAgents(companyId: string): Promise<AgentRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agent +
    "/company/" +
    companyId;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<AgentRow[]>(url, request);
}

export function getAgentsByUser(userId: string): Promise<AgentRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.agent + "/user/" + userId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<AgentRow[]>(url, request);
}

export function updateAgentApiCall(
  updateAgent: Partial<AgentUpdate>,
): Promise<AgentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.agent + `/${updateAgent.id}`;

  const request = {
    method: "PUT",
    body: JSON.stringify(updateAgent),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<AgentRow>(url, request);
}

export function createAgent(
  agent: Partial<AgentInsert> | null,
): Promise<LeadAgentRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.agent + "/create";

  const request = {
    method: "POST",
    body: JSON.stringify(agent),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchData<LeadAgentRow>(url, request);
}

export function getAgentById({
  id,
  leadId,
}: {
  id: string;
  leadId?: string;
}): Promise<AgentRow> {
  const foundToken = localStorage.getItem("access_token");
  const base = foundToken ? `/${id}` : `/public/${id}/?leadId=${leadId}`;

  const url = process.env.REACT_APP_API_URL + ApiEndpoints.agent + base;

  let request;

  if (!foundToken) {
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
  } else {
    request = setHeaderOptions({
      method: "GET",
    });
  }

  return fetchData<AgentRow>(url, request);
}

export function forkAgent({
  agentId,
  companyId,
  leadId,
}: {
  agentId: string;
  companyId: string;
  leadId: string;
}) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.agent + "/fork";

  const request = {
    method: "POST",
    body: JSON.stringify({ agentId, companyId, leadId }),
  };

  return fetchData<AgentRow>(url, request);
}

export function getAgentByName(name: string): Promise<AgentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.agent + `/name/${name}`;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<AgentRow>(request.url, request);
}

export function getDefaultAgent(companyId: string): Promise<AgentRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agent +
    `/default/${companyId}`;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url,
  };

  return fetchData<AgentRow>(request.url, request);
}

export function createDraftAgent({
  type,
  companyId,
}: {
  type: AgentDraftType;
  companyId?: string;
}): Promise<AgentRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.agent + "/draft";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ type, ...{ companyId } }),
  });

  return fetchData<AgentRow>(url, request);
}
