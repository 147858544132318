function MetadataButton({
  children,
  isActive,
  onClick,
}: {
  children: React.ReactNode;
  isActive?: boolean;
  onClick: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className={
        isActive
          ? "rounded-md bg-skin-accentSecondary px-4 py-3 text-center text-sm font-medium text-skin-hover"
          : "rounded-md px-4 py-3 text-center text-sm font-medium text-skin-base hover:bg-skin-fill"
      }
    >
      {children}
    </button>
  );
}

export default MetadataButton;
