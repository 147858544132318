/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import { getCompanyById, updateCompanyById } from "../../../api/company.api";
import { CompanyRow } from "../../../api/company.type";
import { useSessionContext } from "./FabrkSessionContext";
import { useAlertContext } from "../../shared/alert/alertContext";

export const CompanyContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();

  const queryClient = useQueryClient();

  const { companyId } = useSessionContext();

  const [company, setCompany] = useState<CompanyRow>();

  const { mutate: updateCompany } = useMutation(updateCompanyById, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("leadAgents");
      queryClient.invalidateQueries("getLead");
      getCompany(companyId);
      setAlertProps({
        message: "Company updated",
        color: "green",
      });
    },
    onError(error: Error) {},
  });

  const { mutate: getCompany } = useMutation(getCompanyById, {
    onSuccess: async (res) => {
      setCompany(res);
    },
    onError(error: Error) {},
  });

  useEffect(() => {
    if (!company && companyId) {
      getCompany(companyId);
    }
  }, [companyId]);

  const value = { company, updateCompany, getCompany };

  return (
    <companyContext.Provider value={value}>
      {props.children}
    </companyContext.Provider>
  );
};

export const companyContext = createContext({
  company: {} as CompanyRow | undefined,
  updateCompany: {} as UseMutateFunction<
    CompanyRow,
    Error,
    Partial<CompanyRow>,
    unknown
  >,
  getCompany: {} as UseMutateFunction<CompanyRow, Error, string, unknown>,
});

export const useCompanyContext = () => useContext(companyContext);
