import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import DeleteButton from "../shared/forms/DeleteButton";
import GenericSelect from "../shared/forms/GenericSelect";
import { FabrkInput } from "../shared/forms/input/InputBase";
import NestedPropertiesFieldArray from "./NestedParametersForm";
import { ParameterFormValues } from "./hooks/createParameterObjectHelper";

const availableTypes = ["string", "number", "boolean", "array", "object"];

function EditParameterItem({
  nestedName,
  item,
  index,
  control,
  errors,
  register,
  watch,
  remove,
}: {
  nestedName: string;
  item: FieldArrayWithId<any>;
  index: number;
  control: Control<ParameterFormValues, any>;
  errors: any;
  register: UseFormRegister<ParameterFormValues>;
  watch: UseFormWatch<ParameterFormValues>;
  remove: UseFieldArrayRemove;
}) {
  const nestedBase = `${nestedName}.${index}`;

  // @ts-ignore
  const parameterType = watch(`${nestedBase}.type`);

  return (
    <div
      key={item.id}
      className="mb-6 flex flex-col rounded-lg border p-4 text-skin-base"
    >
      <div className="mb-4">
        <label className="block font-medium">Parameter Name</label>
        <FabrkInput
          register={register}
          required={true}
          name={`${nestedBase}.name`}
          // @ts-ignore
          defaultValue={item.name}
          errors={errors}
        />
      </div>
      <div className="mb-4">
        <label className="block font-medium">Type</label>
        <Controller
          render={({ field }) => {
            return (
              <div>
                <GenericSelect
                  values={availableTypes.map((type) => {
                    return {
                      value: type,
                      label: type,
                    };
                  })}
                  setSelected={(value) => field.onChange(value)}
                  selected={field.value}
                />
              </div>
            );
          }}
          // @ts-ignore
          name={`${nestedBase}.type`}
          control={control}
        />
      </div>
      <div className="mb-4">
        <label className="block font-medium">Description</label>
        <FabrkInput
          register={register}
          required={false}
          name={`${nestedBase}.description`}
          // @ts-ignore
          defaultValue={item.description}
          errors={errors}
        />
      </div>
      {/* @ts-ignore */}
      {parameterType !== "object" && (
        <div className="mb-4">
          <label className="block font-medium">Default Value</label>
          <FabrkInput
            register={register}
            required={false}
            name={`${nestedBase}.default`}
            // @ts-ignore
            defaultValue={item.default}
            placeholder="Enter default value (optional)"
            errors={errors}
          />
        </div>
      )}
      {/* @ts-ignore */}
      {parameterType === "object" && (
        <NestedPropertiesFieldArray
          control={control}
          index={index}
          register={register}
          watch={watch}
          errors={errors}
        />
      )}
      <div className="place-self-end">
        <DeleteButton onClick={() => remove(index)} />
      </div>
    </div>
  );
}

export default EditParameterItem;
