/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";
import { UseMutateFunction, useMutation, useQuery } from "react-query";
import {
  createTestContext,
  getTestContextByCampaign,
} from "../../../api/testContext.api";
import {
  TestContextInsert,
  TestContextRow,
} from "../../../api/testContext.type";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useLeadAgentContext } from "./LeadAgentContext";

export const InputConfigContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const [showMessages, setShowMessages] = useState(true);

  const [isSetCustomCss, setIsSetCustomCss] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState("");
  const [textColor, setTextColor] = useState("");
  const [width, setWidth] = useState("");
  const [borderRadius, setBorderRadius] = useState("");

  const { activeLeadAgent } = useLeadAgentContext();

  const [placeholderToUpdate, setPlaceholderToUpdate] = useState<string>("");

  const { data: placeholders, refetch: refetchPlaceholders } = useQuery({
    queryFn: () =>
      getTestContextByCampaign(activeLeadAgent?.agent_id as string),
    queryKey: ["placeholders", activeLeadAgent?.agent_id],
    enabled: activeLeadAgent && activeLeadAgent.agent_id ? true : false,
  });

  const { mutate: addPlaceholder } = useMutation(createTestContext, {
    onSuccess: (data) => {
      refetchPlaceholders();
      setAlertProps({
        message: "Placeholder list updated",
        color: "green",
      });
    },
    onError: (error: any) => {
      setAlertProps({
        message: "There was an error fetching the schema: " + error.message,
        color: "red",
      });
    },
  });

  const value = {
    backgroundColor,
    setBackgroundColor,
    textColor,
    setTextColor,
    width,
    setWidth,
    borderRadius,
    setBorderRadius,
    showMessages,
    setShowMessages,
    placeholders,
    addPlaceholder,
    placeholderToUpdate,
    setPlaceholderToUpdate,
    isSetCustomCss,
    setIsSetCustomCss,
  };

  return (
    <InputConfigContext.Provider value={value}>
      {props.children}
    </InputConfigContext.Provider>
  );
};

export const InputConfigContext = createContext({
  backgroundColor: "",
  setBackgroundColor: (value: string) => {},
  textColor: "",
  setTextColor: (value: string) => {},
  width: "",
  setWidth: (value: string) => {},
  borderRadius: "10",
  setBorderRadius: (value: string) => {},
  showMessages: true,
  setShowMessages: (value: boolean) => {},
  placeholders: {} as TestContextRow | undefined,
  addPlaceholder: {} as UseMutateFunction<
    TestContextRow,
    any,
    TestContextInsert,
    unknown
  >,
  placeholderToUpdate: "",
  setPlaceholderToUpdate: (value: string) => {},
  isSetCustomCss: false,
  setIsSetCustomCss: (value: boolean) => {},
});

export const useInputConfigContext = () => useContext(InputConfigContext);
