import { UserInsert, UserRow, UserUpdate } from "../api/user.type";
import { fetchData } from "./apiHelpers";

export function getUserById({ id }: { id: string }): Promise<UserRow> {
  const url = process.env.REACT_APP_API_URL + "/user/fabrk/" + id;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<UserRow>(url, request);
}

export function updateUserById(updateUser: UserUpdate): Promise<UserRow> {
  const url = process.env.REACT_APP_API_URL + "/user/update";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify(updateUser),
  };

  return fetchData<UserRow>(url, request);
}

export function createUser(user: UserInsert | null): Promise<UserRow[]> {
  const url = process.env.REACT_APP_API_URL + "/user/create";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: user ? JSON.stringify(user) : null,
  };

  return fetch(request.url, request)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((res: { data: UserRow[] }) => {
      const { data } = res;
      return data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}
