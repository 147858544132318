/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getExtractionResponsesByMessageId } from "../../../../api/extractionResponse.api";
import { SmsMessage } from "../../../../api/sms.type";
import { SmsMetadataRow } from "../../../../api/smsMetadata.type";
import MetadataButton from "../../../shared/buttons/MetadataButton";
import { useAgentToolContext } from "../../hooks/AgentToolContext";
import MetadataItemList from "./MetadataItemList";

function MessageMetadataContainer({
  message,
  showMetadata = false,
  userMessageId,
}: {
  message: SmsMessage;
  showMetadata?: boolean;
  userMessageId?: string;
}) {
  const { agentTools } = useAgentToolContext();

  const {
    mutate: getExtractionResponse,

    data: responses,
  } = useMutation(getExtractionResponsesByMessageId);
  const [content, setContent] = useState<string>("context");

  const systemMessages =
    message.sms_metadata &&
    message?.sms_metadata?.filter((msg) => msg.role === "system");
  const functionMessages =
    message?.sms_metadata &&
    (message.sms_metadata.filter(
      (msg) => msg.role === "function",
    ) as unknown as SmsMetadataRow[]);
  const toolMessages =
    message?.sms_metadata &&
    message.sms_metadata.filter((msg) => msg.role === "tool");

  useEffect(() => {
    if (userMessageId && agentTools && agentTools.length > 0) {
      const foundExtractionTool = agentTools.find(
        (c) => c.tool.type === "extraction",
      );
      if (foundExtractionTool) {
        getExtractionResponse(userMessageId);
      }
    }
  }, [userMessageId, agentTools]);

  return (
    <div>
      {showMetadata && (
        <div className="flex w-full flex-col gap-4 rounded-md bg-skin-muted p-3 text-skin-inverse">
          <div className="grid grid-cols-1 rounded-md bg-skin-hover sm:grid-cols-4">
            <MetadataButton
              isActive={content === "context"}
              onClick={() => setContent("context")}
            >
              <span>Context</span>
            </MetadataButton>
            <MetadataButton
              isActive={content === "available"}
              onClick={() => {
                if (content === "available") {
                  setContent("");
                } else {
                  setContent("available");
                }
              }}
            >
              <span>{functionMessages?.length}</span>{" "}
              <span>
                Tool{functionMessages?.length === 1 ? "" : "s"} Available
              </span>
            </MetadataButton>

            <MetadataButton
              isActive={content === "called"}
              onClick={() => setContent("called")}
            >
              <span>
                {toolMessages?.length} Tool
                {toolMessages?.length === 0 ? "s " : " "}
                Called
              </span>
            </MetadataButton>

            <MetadataButton
              isActive={content === "data"}
              onClick={() => setContent("data")}
            >
              <span>{responses?.length}</span> <span>Extraction</span>
            </MetadataButton>
          </div>
          <MetadataItemList
            content={content}
            systemMessages={systemMessages}
            functionMessages={functionMessages}
            toolMessages={toolMessages}
            extractionResponses={responses}
          />
        </div>
      )}
    </div>
  );
}

export default MessageMetadataContainer;
