import { XMarkIcon } from "../shared/icons/icons";

function CloseButton({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <button
      type="button"
      onClick={() => setOpen(false)}
      className="relative rounded-lg bg-skin-fill text-skin-muted hover:bg-skin-accentSecondary hover:text-skin-inverse focus:ring-2 focus:ring-indigo-500"
    >
      <span className="absolute -inset-2.5" />
      <span className="sr-only">Close panel</span>
      <XMarkIcon aria-hidden="true" className="h-6 w-6" />
    </button>
  );
}

export default CloseButton;
