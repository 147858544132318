import { PlusIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import { useLeadAgentContext } from "../../fabrk/hooks/LeadAgentContext";

function NewAgentButton() {
  const { handleCreateNewAgent } = useLeadAgentContext();
  return (
    <Tooltip
      placement="bottom"
      content="Create a new agent"
      className="bg-skin-hover text-skin-base"
    >
      <button onClick={handleCreateNewAgent}>
        <PlusIcon className="h-8 w-8 rounded-md bg-skin-hover p-1 text-skin-base hover:bg-skin-muted hover:text-skin-inverse" />
      </button>
    </Tooltip>
  );
}

export default NewAgentButton;
