import { CompanyConfigRow, CompanyRow } from "./company.type";
import { FontRow } from "./font.type";
import { SmsContentRow } from "./smsContent.type";
import { Visibility } from "./tool.type";

import { PromptRow } from "./prompt.type";
import { TestContextRow } from "./testContext.type";
import { LeadAgentRow } from "./leadAgent.type";
import { CampaignToolRow } from "./agentTool.type";

// Potential agent types:
// {
//    "Personas"
//     "App Families"
//     "Artificial Intelligence"
//     "Business Intelligence"
//     "Commerce"
//     "Communication"
//     "Content & Files"
//     "Human Resources"
//     "Internet of Things"
//     "IT Operations": {},
//     "Lifestyle & Entertainment
//     "Marketing": {},
//     "Productivity": {},
//     "Sales & CRM"
//     "Support": {},
//     "Website & App Building": {}
//
//

export type DeliveryMethods = "sms" | "email" | "email_sms";

export type AgentFork = {
  id: string;
  child_agent_id?: string;
  parent_agent_id?: string;
};

export type AgentRow = {
  default?: boolean;
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  goal: string;
  company?: CompanyRow;
  system_prompt?: PromptRow;
  system_prompt_id?: string;
  sms_content?: SmsContentRow[];
  company_id?: string;
  tag_line?: string;
  title?: string;
  step_one_text?: string;
  klaviyo_list_id?: string;
  reimbursement_criteria?: string;
  company_config?: CompanyConfigRow[];
  hero_image_url?: string;
  status: string;
  follow_up_disabled?: boolean;
  title_font?: FontRow;
  paragraph_font?: FontRow;
  subtitle_font?: FontRow;
  title_font_weight?: number;
  paragraph_font_weight?: number;
  type?: AgentDraftType;
  multiple_company_reimbursements_allowed: boolean;
  subtitle_font_weight?: number;
  shopify_default?: boolean;
  shopify_abandoned_cart?: boolean;
  delivery_method?: DeliveryMethods;
  test_context?: TestContextRow[];
  fabrk_public?: boolean;
  agent_fork?: AgentFork[];
  lead_agent?: LeadAgentRow[];
  agent_ai_function?: CampaignToolRow[];
  visibility: Visibility;
  image_path?: string;
};

export type AgentInsert = {
  lead_id: string;
  company_id: string;
};

export type AgentUpdate = Partial<AgentInsert> & {
  id: string;
  name?: string;
  sms_content?: Partial<SmsContentRow>;
  created_at?: string;
  system_prompt?: PromptRow;
  system_prompt_id?: string;
  tag_line?: string;
  title?: string;
  step_one_text?: string;
  klaviyo_list_id?: string;
  company_id?: string;
  status?: string;
  follow_up_disabled?: boolean;
  reimbursement_criteria?: string;
  hero_image_url?: string;
  shopify_default?: boolean;
  shopify_abandoned_cart?: boolean;
  delivery_method?: DeliveryMethods;
  fabrk_public?: boolean;
  goal?: string;
  visibility?: Visibility;
};

export type AgentForPaths = {
  params: {
    Agent: string;
  };
};

export enum AgentDraftType {
  personas = "personas",
  businessIntelligence = "businessIntelligence",
  commerce = "commerce",
  communication = "communication",
  contentAndFiles = "contentAndFiles",
  humanResources = "humanResources",
  itOperations = "itOperations",
  lifestyleAndEntertainment = "lifestyleAndEntertainment",
  marketing = "marketing",
  productivity = "productivity",
  salesAndCRM = "salesAndCRM",
  support = "support",
  websiteAndAppBuilding = "websiteAndAppBuilding",
}

export type AgentTypeWithLabel = {
  value: AgentDraftType;
  label: string;
};

export const AgentTypes = [
  {
    value: AgentDraftType.personas,
    label: "Personas",
  },
  {
    value: AgentDraftType.businessIntelligence,
    label: "Business Intelligence",
  },
  {
    value: AgentDraftType.commerce,
    label: "Commerce",
  },
  {
    value: AgentDraftType.communication,
    label: "Communication",
  },
  {
    value: AgentDraftType.contentAndFiles,
    label: "Content & Files",
  },
  {
    value: AgentDraftType.humanResources,
    label: "Human Resources",
  },
  {
    value: AgentDraftType.itOperations,
    label: "IT Operations",
  },
  {
    value: AgentDraftType.lifestyleAndEntertainment,
    label: "Lifestyle & Entertainment",
  },
  {
    value: AgentDraftType.marketing,
    label: "Marketing",
  },
  {
    value: AgentDraftType.productivity,
    label: "Productivity",
  },
  {
    value: AgentDraftType.salesAndCRM,
    label: "Sales & CRM",
  },
  {
    value: AgentDraftType.support,
    label: "Support",
  },
  {
    value: AgentDraftType.websiteAndAppBuilding,
    label: "Website & App Building",
  },
];

export function mapTypeToLabel(type: AgentDraftType): string {
  const found = AgentTypes.find((t) => t.value === type);
  return found ? found?.label : "N/A";
}

export function mapLabelToType(label: string): AgentTypeWithLabel | undefined {
  const found = AgentTypes.find((t) => t.label === label);
  return found ? found : undefined;
}
