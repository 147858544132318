import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";
import { AddFileStoreToTool, FileStoreRow } from "./fileStore.type";

export function createFileStoreTool(
  values: AddFileStoreToTool,
): Promise<FileStoreRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.fileStore;

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify(values),
  };

  return fetchData<FileStoreRow>(url, request);
}
