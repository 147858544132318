import clsx from "clsx";
import { ToolRow } from "../../api/tool.type";
import { useLoadPublicTools } from "../tools/hooks/usePublicTools";
import { useAgentToolContext } from "../fabrk/hooks/AgentToolContext";
import ToolItemFooter from "./ToolItemFooter";
import ToolItemHeader from "./ToolItemHeader";
import { ToolContextWrapper } from "../tools/hooks/ToolContext";
import { useSessionContext } from "../fabrk/hooks/FabrkSessionContext";
import { useLoadUsersTools } from "../tools/hooks/useLoadUserTools";

export default function ToolGrid() {
  const { agentTools } = useAgentToolContext();

  const { publicTools } = useLoadPublicTools();

  // TODO - add the company tools here also
  const { companyId } = useSessionContext();

  const { usersTools } = useLoadUsersTools(companyId);

  return (
    <div>
      {agentTools && agentTools.length > 0 && (
        <p className="mx-1 my-4 rounded-lg bg-skin-accentSecondary text-center text-xl text-skin-inverse">
          Tools added to this agent:
        </p>
      )}
      <ul className="grid w-full grid-cols-1 gap-4 p-2 md:grid-cols-2">
        {agentTools &&
          agentTools.length > 0 &&
          agentTools.map((tool) => {
            return (
              <li
                key={tool.tool.id}
                className="md:min-h-md col-span-1 mx-auto flex w-full flex-col justify-between divide-y divide-skin-hover rounded-lg border-2 border-skin-hover bg-skin-hover shadow"
              >
                <ToolContextWrapper>
                  <ToolItemHeader tool={tool.tool} />
                  <ToolItemFooter tool={tool.tool} />
                </ToolContextWrapper>
              </li>
            );
          })}
      </ul>
      <p className="mx-1 my-4 rounded-lg bg-skin-accentSecondary text-center text-xl text-skin-inverse">
        Your Tools:
      </p>
      <ul className="grid w-full grid-cols-1 gap-4 p-2 md:grid-cols-2">
        {usersTools &&
          usersTools.length > 0 &&
          usersTools
            .filter(
              (t) =>
                !agentTools?.some((at) => {
                  return at.tool.id === t.id;
                }),
            )
            .map((tool) => {
              return (
                <li
                  key={tool.id}
                  className="md:min-h-md col-span-1 mx-auto flex w-full flex-col justify-between divide-y divide-skin-hover rounded-lg border-2 border-skin-hover bg-skin-fill shadow"
                >
                  <ToolContextWrapper>
                    <ToolItemHeader tool={tool} />
                    <ToolItemFooter tool={tool} />
                  </ToolContextWrapper>
                </li>
              );
            })}
      </ul>

      <p className="mx-1 my-4 rounded-lg bg-skin-accentSecondary text-center text-xl text-skin-inverse">
        Publicly Available Tools:
      </p>

      <ul className="grid w-full grid-cols-1 gap-4 p-2 md:grid-cols-2">
        {publicTools &&
          publicTools
            .filter((tool) => {
              return !agentTools?.some(
                (agentTool) => agentTool.tool.id === tool.id,
              );
            })
            .map((tool: ToolRow) => {
              return (
                <ToolContextWrapper>
                  <li
                    key={tool.name}
                    className={clsx(
                      "min-h-md col-span-1 mx-auto flex w-full flex-col justify-between rounded-lg border-2 border-skin-hover bg-skin-fill shadow",
                    )}
                  >
                    <ToolItemHeader tool={tool} />
                    <ToolItemFooter tool={tool} />
                  </li>
                </ToolContextWrapper>
              );
            })}
      </ul>
    </div>
  );
}
