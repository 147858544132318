import { CampaignToolRow, CampaignToolRowUpdate } from "./agentTool.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";

export function getCampaignToolByCampaign(
  agentId: string,
): Promise<CampaignToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/fabrk/agent/" +
    agentId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<CampaignToolRow[]>(url, request);
}

export function addCampaignTool({
  agentId,
  toolId,
}: {
  agentId: string;
  toolId: string;
}): Promise<CampaignToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/agent/" +
    agentId +
    "/tool/" +
    toolId;

  const request = { method: "PUT" };

  return fetchData<CampaignToolRow[]>(url, request);
}

export function updateAiCampaignFunction({
  agentIdFunctionId,
  values,
}: {
  agentIdFunctionId: string;
  values: CampaignToolRowUpdate;
}): Promise<CampaignToolRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/" +
    agentIdFunctionId;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<CampaignToolRow>(url, request);
}

export function deleteAiCampaignFunction({
  agentId,
  toolId,
}: {
  agentId: string;
  toolId: string;
}): Promise<CampaignToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/agent/" +
    agentId +
    "/tool/" +
    toolId;

  const request = { method: "DELETE" };

  return fetchData<CampaignToolRow[]>(url, request);
}
