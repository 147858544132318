import { useState } from "react";
import { AgentRow } from "../../api/agent.type";
import LcList from "./LeadCampaignList";
import ThreadNavItem from "./ThreadNavItem";

function ThreadNavItemWithSubList({
  agent,
  isActive,
}: {
  agent: AgentRow;
  isActive: boolean;
}) {
  const [isOpen, setIsOpen] = useState(isActive);
  return (
    <div key={agent.id} className="w-full">
      <ThreadNavItem
        a={agent}
        isActive={isActive}
        toggle={() => setIsOpen(!isOpen)}
        isOpen={isOpen}
      />

      {isOpen && (
        <LcList
          leadAgents={agent?.lead_agent
            ?.sort((a, b) => {
              return a.created_at < b.created_at ? 1 : -1;
            })
            .map((lc) => ({ ...lc, id: lc.id }))}
        />
      )}
    </div>
  );
}

export default ThreadNavItemWithSubList;
