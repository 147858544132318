import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMessageContext } from "../../components/fabrk/hooks/MessageContext";
import { useScreenSize } from "../../components/fabrk/layout/hooks/ScreenSizeContext";
import MessagesContainer from "../../components/fabrk/messageView/MessagesContainer";
import LayoutContainer from "../../components/layout/LayoutContainer";
import MenuContainer from "../../components/menu/MenuContainer";
import RightIconMenu from "../../components/menu/RightIconMenu";
import ToolsDialogMenu from "../../components/menu/ToolsDialogMenu";
import TextAreaContainer from "../../components/shared/forms/textArea/TextAreaContainer";

function HomeContainer() {
  const { agentId, lcId } = useParams();

  const { messages } = useMessageContext();

  const [menuType, setMenuType] = useState("tools");
  const [menuOpen, setMenuOpen] = useState(false);
  const { width } = useScreenSize();

  function handleToggle(menu: string) {
    setMenuType(menu);

    if (menuOpen && menuType === menu) {
      setMenuOpen(false);
    } else if (menu === "meta") {
      if (menuType === "meta") {
        setMenuType("");
      }
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  }

  useEffect(() => {
    if (agentId && lcId) {
      setMenuOpen(true);
      setMenuType("settings");
    }
  }, [agentId, lcId]);

  return (
    <LayoutContainer>
      <div className="relative flex min-h-screen flex-row bg-skin-fill text-skin-base">
        <div
          className={
            messages && messages.length > 0
              ? "mb-40 flex flex-1 flex-col justify-end gap-4 p-4"
              : "mx-auto my-64 flex h-full w-full flex-col gap-20 align-middle"
          }
        >
          {messages && messages.length > 0 && (
            <MessagesContainer menuType={menuType} />
          )}
          {(!messages || messages.length === 0) && (
            <div className="mx-auto mt-3 w-full">
              <Typography variant="h1" className="text-center">
                The Worlds First{" "}
                <span className="text-skin-accent">Agent Engine</span>
              </Typography>
            </div>
          )}

          <TextAreaContainer handleToggle={handleToggle} />
        </div>

        {menuOpen && (
          <>
            {width > 960 ? (
              <MenuContainer menuType={menuType} setOpen={setMenuOpen} />
            ) : (
              <ToolsDialogMenu
                open={menuOpen && width < 960}
                setOpen={setMenuOpen}
                menuType={menuType}
              />
            )}
          </>
        )}
        <RightIconMenu handleToggle={handleToggle} menuType={menuType} />
      </div>
    </LayoutContainer>
  );
}

export default HomeContainer;
