export function CenteredNarrowContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-3xl">{children}</div>
    </div>
  );
}

export function ColumnContainer({ children }: { children: React.ReactNode }) {
  return <div className="flex flex-col gap-2">{children}</div>;
}

export function FullWidthContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">{children}</div>
  );
}

export function ThreeColumnContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">{children}</div>
  );
}

export function DiscoverGrid({ children }: { children: React.ReactNode }) {
  return (
    <div className="mx-auto mt-10 grid max-w-lg grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 px-4 pt-10 sm:mt-16 sm:pt-16 md:grid-cols-2 lg:mx-0 lg:max-w-none xl:grid-cols-3">
      {children}
    </div>
  );
}

export function DiscoverGridItem(
  {
    children,
  }: {
    children: React.ReactNode;
  },
  props: any,
) {
  return (
    <div
      {...props}
      className="flex min-h-96 max-w-lg flex-col items-start justify-between rounded-lg border-2 border-skin-hover bg-skin-hover p-4 shadow"
    >
      {children}
    </div>
  );
}
