import { useState } from "react";
import FabrkMenuDivider from "../fabrk/layout/FabrkMenuDivider";
import IconButton from "../shared/buttons/IconButton";
import SubmitButton from "../shared/buttons/SubmitButton";
import { TextArea } from "../shared/forms/input/InputBase";
import { EditIcon } from "../shared/icons/icons";
import { useToolContext } from "./hooks/ToolContext";
import ParametersContainer from "./ParametersContainer";
import ViewToolHeader from "./ViewToolHeader";

export default function ViewTool() {
  const { tool, updateTool } = useToolContext();

  const [toolContext, setToolContext] = useState(tool?.context || "");

  const [editContext, setEditContext] = useState(false);

  function handleSubmitContext() {
    updateTool({ id: tool?.id as string, context: toolContext });
    setEditContext(false);
  }

  return (
    <div className="h-full min-h-screen bg-skin-fill py-24 sm:py-32">
      <ViewToolHeader />

      <div className="mt-16 grid grid-cols-1 gap-8 px-6 md:grid-cols-2 lg:px-8">
        <div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col gap-4">
              <p className="font-semibold leading-7 text-skin-muted/80">
                Context
              </p>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-skin-base/40">
                Free text passed into this tool by the AI
              </p>
            </div>
            <IconButton
              onClick={() => {
                if (editContext) {
                  setToolContext(tool?.context || "");
                }
                setEditContext(!editContext);
              }}
            >
              <EditIcon className="h-5 w-5" />
            </IconButton>
          </div>
          <FabrkMenuDivider />
          {editContext ? (
            <div className="mt-4 flex flex-col gap-4">
              <TextArea value={toolContext} setValue={setToolContext} />
              <div className="flex justify-end">
                <SubmitButton
                  type="submit"
                  onClick={handleSubmitContext}
                  disabled={toolContext === tool?.context || toolContext === ""}
                />
              </div>
            </div>
          ) : (
            <p className="mt-4">{tool?.context || "No context listed"}</p>
          )}
        </div>
        <ParametersContainer />
      </div>
    </div>
  );
}
