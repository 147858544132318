import { useState } from "react";
import { AgentRow } from "../../../api/agent.type";
import { useMessageContext } from "../../fabrk/hooks/MessageContext";
import BaseChip from "../../shared/chip/BaseChip";
import { LabelBase } from "../../shared/typography/TypographyBase";
import { useEventStreaming } from "../../message/StreamMessageContext";
import { useLeadAgentContext } from "../../fabrk/hooks/LeadAgentContext";

function RunPersonaList({
  personas,
  setDialogOpen,
}: {
  personas: AgentRow[];
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { setCounter, handleCreateMessage, setPersona, messages } =
    useMessageContext();

  const { activeLeadAgent } = useLeadAgentContext();

  const { startStreamingPersona } = useEventStreaming();

  const [cycles] = useState(3);

  function handleStartCycle(persona: AgentRow) {
    setPersona(persona);
    setCounter(cycles);

    if (messages && messages?.length > 0) {
      startStreamingPersona(
        activeLeadAgent?.id as string,
        persona?.id as string,
      );
    } else {
      handleCreateMessage({
        content: `Hi my name is ${persona.name}.`,
      });
    }

    setDialogOpen(false);
  }

  return (
    <div className="flex flex-col gap-4">
      <LabelBase>Cycles: {cycles}</LabelBase>

      {personas.map((persona) => {
        return (
          <button key={persona.id} onClick={() => handleStartCycle(persona)}>
            <BaseChip>{persona.name}</BaseChip>
          </button>
        );
      })}
    </div>
  );
}

export default RunPersonaList;
