import { createContext, useContext } from "react";
import { UseMutateFunction, useMutation } from "react-query";
import { createAction } from "../../api/action.api";
import { useAlertContext } from "../shared/alert/alertContext";
import { ActionInsert, ActionRow } from "../../api/action.type";

export const ActionContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();

  const { mutate: create } = useMutation(createAction, {
    onSuccess: async (res) => {
      setAlertProps({
        message:
          "Action created. Navigate to the home screen to see available actions.",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const value = {
    create,
  };

  return (
    <ActionContext.Provider value={value}>
      {props.children}
    </ActionContext.Provider>
  );
};

export const ActionContext = createContext({
  create: {} as UseMutateFunction<
    ActionRow,
    Error,
    Partial<ActionInsert> | null,
    unknown
  >,
});

export const useActionContext = () => {
  return useContext(ActionContext);
};
