import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAlertContext } from "../alert/alertContext";

const FloatingAlert = () => {
  const { alertProps } = useAlertContext();
  const { color, message } = alertProps;

  const show = alertProps && message && color;

  return show ? (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 mr-20 mt-20 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition show={true}>
          <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-skin-hover shadow-lg ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {color === "green" ? (
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-green-400"
                    />
                  ) : (
                    <XMarkIcon
                      aria-hidden="true"
                      className="h-6 w-6 text-red-400"
                    />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p className="text-sm font-medium text-skin-base">
                    {message}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  ) : null;
};

export default FloatingAlert;
