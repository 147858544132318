import {
  ArrowDownCircleIcon,
  ArrowLongUpIcon,
  CalendarDaysIcon,
  ChartBarSquareIcon,
  ClockIcon,
  MagnifyingGlassIcon,
  UserCircleIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import { HackerNewsLogo } from "../../shared/icons/hackerNewsSvg";
import { DollarIcon, MailIcon, MapIcon } from "../../shared/icons/icons";
import { MemoryCardIcon } from "../../shared/icons/memoryCardSvg";
import { ShopifyBag } from "../../shared/icons/shopifyBag";

export const toolIcons = [
  {
    name: "File Search",
    icon: <MagnifyingGlassIcon className="h-6 w-6" />,
    slug: "file_search",
  },
  {
    name: "Data Analysis",
    icon: <ChartBarSquareIcon className="h-6 w-6" />,
    slug: "data_analysis",
  },
  {
    name: "API Call",
    icon: <ArrowLongUpIcon className="h-6 w-6" />,
    slug: "api_call",
  },
  {
    name: "Location",
    icon: <MapIcon className="h-6 w-6" />,
    slug: "location",
  },
  {
    name: "Memory",
    icon: <MemoryCardIcon className="h-6 w-6" />,
    slug: "memory",
  },
  {
    name: "Schedule",
    icon: <ClockIcon className="h-6 w-6" />,
    slug: "schedule",
  },
  {
    name: "Email",
    icon: <MailIcon className="h-6 w-6" />,
    slug: "email",
  },

  {
    name: "Calendar",
    icon: <CalendarDaysIcon className="h-6 w-6" />,
    slug: "calendar",
  },
  {
    name: "Hacker News",
    icon: <HackerNewsLogo className="h-6 w-6" />,
    slug: "hacker-news",
  },

  {
    name: "Shopify",
    icon: <ShopifyBag className="h-6 w-6" />,
    slug: "shopify",
  },

  {
    name: "Money",
    icon: <DollarIcon className="h-6 w-6" />,
    slug: "reimbursement",
  },

  {
    name: "Custom",
    icon: <WrenchScrewdriverIcon className="h-6 w-6" />,
    slug: "custom",
  },

  {
    name: "Extraction",
    icon: <ArrowDownCircleIcon className="h-6 w-6" />,
    slug: "extraction",
  },

  {
    name: "Default",
    icon: <UserCircleIcon className="h-6 w-6" />,
    slug: "default",
  },
];

export const getToolIcon = ({
  slug,
  name,
  type,
}: {
  slug?: string;
  name?: string;
  type?: string;
}) => {
  const foundTool = toolIcons.find(
    (a) => a.slug === slug || a.name === name || a.slug === type,
  );

  return foundTool?.icon || <WrenchScrewdriverIcon className="h-6 w-6" />;
};
