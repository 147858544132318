/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import { useSession } from "@supabase/auth-helpers-react";
import { useAgentContext } from "../../../agent/hooks/AgentContext";
import SubmitButton from "../../../shared/buttons/SubmitButton";
import LoginSignupButtons from "../loginSignup/LoginSignupButtons";

function ShareAgentForm({
  setDialogOpen,
}: {
  setDialogOpen: (open: boolean) => void;
}) {
  const { updateAgent, activeAgent } = useAgentContext();
  const session = useSession();

  const handleSubmit = () => {
    updateAgent({
      visibility: activeAgent?.visibility === "public" ? "private" : "public",
      id: activeAgent?.id,
    });

    setDialogOpen(false);
  };

  return (
    <div className="flex flex-col gap-4 p-5">
      {activeAgent?.fabrk_public ? (
        <Typography variant="paragraph">
          Make {activeAgent.name} private.
        </Typography>
      ) : (
        <Typography variant="paragraph">
          Make {activeAgent?.name} public so others can chat with it or fork it.
        </Typography>
      )}
      {session ? (
        <form onSubmit={() => handleSubmit()}>
          <SubmitButton fullWidth={true} type="submit">
            Make {activeAgent?.name}{" "}
            {activeAgent?.visibility === "public" ? "Private" : "Public"}
          </SubmitButton>
        </form>
      ) : (
        <div className="flex flex-col items-center gap-10">
          <Typography variant="small">
            Please login or signup to share your agent
          </Typography>
          <LoginSignupButtons />
        </div>
      )}
    </div>
  );
}

export default ShareAgentForm;
