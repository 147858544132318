import React, { useState } from "react";

function GenericSelect({
  values,
  setSelected,
  selected,
  placeholder,
}: {
  values: {
    value: string;
    label: string;
  }[];
  setSelected: (value: string) => void;
  selected?: string;
  placeholder?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(selected || "");

  const handleValueChange = (value: string) => {
    setSelected(value);
    setSelectedValue(value);
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="relative w-full">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="flex h-10 w-full min-w-36 rounded-md bg-skin-hover p-2 shadow-sm focus:border-2 focus:border-skin-accentSecondary focus:text-skin-base focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent sm:text-sm sm:leading-6"
      >
        {selectedValue
          ? values.find((v) => v.value === selectedValue)?.label
          : placeholder || "Select an option"}
      </button>

      {isOpen && (
        <ul className="absolute z-10 mt-1 w-full rounded-md border border-skin-hover bg-skin-fill shadow-lg">
          {values.map((option) => (
            <li
              key={option.value}
              className="cursor-pointer p-2 hover:bg-skin-hover"
              onClick={() => handleValueChange(option.value)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
        <svg
          className="h-5 w-5 text-skin-base/60"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>
  );
}

export default GenericSelect;
