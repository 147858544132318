import clsx from "clsx";

function IconButton({
  children,
  onClick,
  active,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
}) {
  return (
    <button onClick={onClick}>
      <span
        className={clsx(
          "inline-flex items-center gap-2 rounded-full px-2 py-1 text-sm font-medium ring-1 ring-inset ring-gray-500/10",
          active
            ? "bg-skin-accentSecondary text-skin-hover"
            : "bg-skin-hover text-skin-muted hover:bg-skin-accent hover:text-skin-inverse",
        )}
      >
        {children}
      </span>
    </button>
  );
}

export default IconButton;
