import { DataValueBase, LabelBase } from "../typography/TypographyBase";

type ValueWithLabelProps = {
  label: string;
  value: string;
  font?: string;
  otherProps?: any;
};

function ValueWithLabel({
  label,
  value,
  font,
  otherProps,
}: ValueWithLabelProps) {
  return (
    <div {...otherProps}>
      <LabelBase>{label}</LabelBase>
      <DataValueBase>{value}</DataValueBase>
    </div>
  );
}

export default ValueWithLabel;
