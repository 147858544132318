import LayoutContainer from "../../components/layout/LayoutContainer";
import { ToolContextWrapper } from "../../components/tools/hooks/ToolContext";
import ViewTool from "../../components/tools/ViewTool";

function ViewToolContainer() {
  return (
    <ToolContextWrapper>
      <LayoutContainer>
        <ViewTool />
      </LayoutContainer>
    </ToolContextWrapper>
  );
}

export default ViewToolContainer;
