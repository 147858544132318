/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { getAgentById, updateAgentApiCall } from "../../../api/agent.api";
import { AgentRow, AgentUpdate } from "../../../api/agent.type";
import { updateSystemPrompt } from "../../../api/prompt.api";
import { PromptRow, PromptUpdate } from "../../../api/prompt.type";
import { useLeadContext } from "../../fabrk/hooks/LeadContext";
import { useAlertContext } from "../../shared/alert/alertContext";

export const AgentContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();

  const queryClient = useQueryClient();

  const { agentId } = useParams();
  const { lead } = useLeadContext();

  const {
    mutate: getAgent,
    data: activeAgent,
    isLoading,
  } = useMutation(getAgentById);

  const { mutate: updateAgent } = useMutation(updateAgentApiCall, {
    onSuccess: async (res) => {
      getAgent({
        id: res.id,
      });

      setAlertProps({
        message: "Agent updated",
        color: "green",
      });
    },
    onError(error: Error) {},
  });

  const { mutate: updatePrompt } = useMutation(updateSystemPrompt, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("leadAgents");

      setAlertProps({
        message: "Instructions updated",
        color: "green",
      });
    },
    onError(error: Error) {},
  });

  useEffect(() => {
    if (agentId) {
      getAgent({
        id: agentId,
        leadId: lead?.id,
      });
    }
  }, [agentId]);

  const value = {
    updateAgent,
    getAgent,
    activeAgent,
    loading: isLoading,
    updatePrompt,
  };

  return (
    <AgentContext.Provider value={value}>
      {props.children}
    </AgentContext.Provider>
  );
};

export const AgentContext = createContext({
  updatePrompt: {} as UseMutateFunction<
    PromptRow,
    Error,
    Partial<PromptUpdate>,
    unknown
  >,
  updateAgent: {} as UseMutateFunction<
    AgentRow,
    Error,
    Partial<AgentUpdate>,
    unknown
  >,
  getAgent: {} as UseMutateFunction<
    AgentRow,
    unknown,
    {
      id: string;
      leadId?: string;
    },
    unknown
  >,
  activeAgent: {} as AgentRow | undefined,
  loading: false,
});

export const useAgentContext = () => useContext(AgentContext);
