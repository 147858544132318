import { Typography } from "@material-tailwind/react";

import { useAgentToolContext } from "../../fabrk/hooks/AgentToolContext";
import { useLeadAgentContext } from "../../fabrk/hooks/LeadAgentContext";
import SubmitButton from "../../shared/buttons/SubmitButton";
import { useToolContext } from "../../tools/hooks/ToolContext";

function AddToolModel({
  setDialogOpen,
}: {
  setDialogOpen: (open: boolean) => void;
}) {
  const { activeLeadAgent } = useLeadAgentContext();
  const { addToolToAgent } = useAgentToolContext();
  const { selectedTool } = useToolContext();

  function addTool() {
    addToolToAgent({
      toolId: selectedTool?.id as string,
      agentId: activeLeadAgent?.agent_id as string,
    });
    setDialogOpen(false);
  }

  return (
    <div className="flex flex-col gap-4">
      <Typography>{selectedTool?.description}</Typography>

      <SubmitButton type="submit" onClick={addTool}>
        Enable
      </SubmitButton>
    </div>
  );
}

export default AddToolModel;
