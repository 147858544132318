import { ToolCreate, ToolRow } from "./tool.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { CompanyToolRow, CompanyToolUpdate } from "./companyTool.type";

export function getAvailableTools(companyId?: string): Promise<ToolRow[]> {
  const companySearchParams = companyId ? "?companyId=" + companyId : "";

  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.companyTool +
    companySearchParams;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ToolRow[]>(url, request);
}

export function updateCompanyTool({
  id,
  values,
}: {
  id: string;
  values: CompanyToolUpdate;
}): Promise<CompanyToolRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.companyTool + "/" + id;

  const request = setHeaderOptions({
    method: "PUT",
    body: JSON.stringify(values),
  });

  return fetchData<CompanyToolRow>(url, request);
}

export function createCompanyAiAgent(values: ToolCreate): Promise<ToolRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.companyTool;

  const request = {
    method: "POST",
    body: JSON.stringify({ ...values }),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ToolRow>(url, request);
}
