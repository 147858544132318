/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { QuestionTypes } from "../../api/extractionSchema.type";
import { useExtractionSchemaContext } from "../fabrk/hooks/ExtractionSchemaContext";
import FabrkMenuDivider from "../fabrk/layout/FabrkMenuDivider";
import IconButton from "../shared/buttons/IconButton";
import { PlusIcon } from "../shared/icons/icons";
import EditSchema from "./EditSchema";
import ExtractionSchemaList from "./ExtractionSchemaList";
import { useToolContext } from "./hooks/ToolContext";

function ExtractionParametersForm() {
  const { tool } = useToolContext();

  const { getToolSchema, extractionSchemaCampaigns, loading } =
    useExtractionSchemaContext();

  const [isAddSchema, setIsAddSchema] = useState(true);

  function handleAddParams() {
    setIsAddSchema(true);
  }

  const templateSchema = [
    {
      id: "1",
      question: "What is your name?",
      type: QuestionTypes.TEXT,
      required: true,
    },
    {
      id: "2",
      question: "What is your email?",
      type: QuestionTypes.TEXT,
      required: true,
    },
  ];

  useEffect(() => {
    if (tool) {
      getToolSchema(tool.id);
    }
  }, [tool]);

  useEffect(() => {
    if (
      !loading &&
      extractionSchemaCampaigns &&
      extractionSchemaCampaigns.length > 0
    ) {
      setIsAddSchema(false);
    }
  }, [extractionSchemaCampaigns]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <div>
          <p className="font-semibold leading-7 text-skin-muted/80">
            Extraction Parameters
          </p>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-skin-base/40">
            The questions the AI should ask the user and pull out of the chat{" "}
          </p>
        </div>

        <IconButton onClick={handleAddParams}>
          <PlusIcon className="h-5 w-5" />
        </IconButton>
      </div>
      <FabrkMenuDivider />
      {extractionSchemaCampaigns &&
        extractionSchemaCampaigns.length > 0 &&
        extractionSchemaCampaigns[0].extraction_schema && (
          <ExtractionSchemaList
            schema={extractionSchemaCampaigns.map((ec) => ec.extraction_schema)}
          />
        )}
      {isAddSchema && (
        <EditSchema
          name={`${tool?.name} data to extract`}
          setIsEdit={setIsAddSchema}
          schema={templateSchema}
          toolId={tool?.id as string}
        />
      )}
    </div>
  );
}

export default ExtractionParametersForm;
