import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { BoltIcon } from "@heroicons/react/24/outline";
import { ArrowUTurnLeft } from "../../shared/icons/icons";

function ActionMenu({
  toggle,
  rerun,
}: {
  toggle: () => void;
  rerun: () => void;
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="t inline-flex w-full justify-center rounded-md p-1 text-sm font-semibold hover:bg-skin-hover">
          <BoltIcon aria-hidden="true" className="h-4 w-4 text-skin-muted" />
        </MenuButton>
      </div>

      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-skin-fill shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem>
            <button
              onClick={rerun}
              className="group flex w-full items-center px-4 py-2 text-sm text-skin-base data-[focus]:bg-skin-muted data-[focus]:text-skin-inverse"
            >
              <ArrowUTurnLeft
                aria-hidden="true"
                className="mr-3 h-5 w-5 text-skin-muted group-hover:text-skin-inverse"
              />
              Rerun
            </button>
          </MenuItem>
          <MenuItem>
            <button
              onClick={toggle}
              className="group flex w-full items-center px-4 py-2 text-sm text-skin-base data-[focus]:bg-skin-muted data-[focus]:text-skin-inverse"
            >
              <BoltIcon
                aria-hidden="true"
                className="mr-3 h-5 w-5 text-skin-muted group-hover:text-skin-inverse"
              />
              Create action
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
  );
}

export default ActionMenu;
