import { ToolRow } from "../../api/tool.type";
import { getToolIcon } from "../fabrk/config/ToolIcons";

function ToolItemHeader({ tool }: { tool: ToolRow }) {
  return (
    <div className="flex w-full flex-col items-center justify-between gap-4 space-x-6 px-2 py-4">
      <div className="flex flex-row gap-4">
        <span className="inline-flex items-center rounded-full bg-skin-accentSecondary px-2 py-1 text-sm font-medium text-skin-hover ring-1 ring-inset ring-gray-500/10">
          {getToolIcon(tool)}
        </span>
        <div className="flex max-w-52 items-center space-x-3">
          <h3 className="text-md text-wrap font-medium text-skin-base">
            {tool.name}
          </h3>
        </div>
      </div>
      <p className="mt-1 text-wrap text-sm text-skin-muted">
        {tool.description}
      </p>
    </div>
  );
}

export default ToolItemHeader;
