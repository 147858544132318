function FabrkMenuDivider() {
  return (
    <div
      className="my-1 w-full bg-skin-base opacity-70"
      style={{
        height: 1,
      }}
    ></div>
  );
}

export default FabrkMenuDivider;
