import { formatTimeStampToHumanReadableShortDateTime } from "../../../utils/parseTimeStamp";
import FabrkMenuDivider from "../../fabrk/layout/FabrkMenuDivider";
import BaseChip from "../../shared/chip/BaseChip";
import { useAgentContext } from "../hooks/AgentContext";
import ViewAgentHeader from "./ViewAgentHeader";

export default function ViewAgent() {
  const { activeAgent } = useAgentContext();

  return (
    <div className="h-full min-h-screen bg-skin-fill py-24 sm:py-32">
      <ViewAgentHeader />

      <div className="mt-16 grid grid-cols-1 gap-8 px-6 md:grid-cols-2 lg:px-8">
        <div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col gap-4">
              <p className="font-semibold leading-7 text-skin-muted/80">
                Tools
              </p>
            </div>
          </div>
          <FabrkMenuDivider />
          {activeAgent &&
          activeAgent.agent_ai_function &&
          activeAgent?.agent_ai_function?.length > 0 ? (
            <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
              {activeAgent?.agent_ai_function.map((ai) => (
                <a
                  key={ai.id}
                  href={`/tool/${ai.tool_id}?agentId=${activeAgent.id}`}
                >
                  <BaseChip>{ai.tool.name}</BaseChip>
                </a>
              ))}
            </div>
          ) : (
            <p>No tools available for this agent.</p>
          )}
        </div>
        <div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-col gap-4">
              <p className="font-semibold leading-7 text-skin-muted/80">
                Threads
              </p>
            </div>
          </div>
          <FabrkMenuDivider />
          {activeAgent &&
          activeAgent.lead_agent &&
          activeAgent?.lead_agent?.length > 0 ? (
            <div className="grid grid-cols-1 gap-2">
              {activeAgent?.lead_agent
                .sort((a, b) => {
                  return (
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                  );
                })
                .map((lc) => {
                  const fullName = lc.lead?.first_name
                    ? `${lc.lead?.first_name} ${lc.lead?.last_name}`
                    : "";

                  return (
                    <a key={lc.id} href={`/a/${lc.agent_id}/t/${lc.id}`}>
                      <BaseChip>
                        {formatTimeStampToHumanReadableShortDateTime(
                          lc.created_at,
                        )}{" "}
                        {fullName ? `- ${fullName}` : ""}
                      </BaseChip>
                    </a>
                  );
                })}
            </div>
          ) : (
            <p>No tools available for this agent.</p>
          )}
        </div>
      </div>
    </div>
  );
}
