import { PhoneIcon } from "@heroicons/react/24/outline";
import React from "react";
import InputMask from "react-input-mask";

function PhoneNumberInput({
  value,
  setValue,
}: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) {
  const handleOnChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setValue(event.target.value);
  };

  return (
    <InputMask mask="(999) 999-9999" value={value} onChange={handleOnChange}>
      {/* @ts-ignore */}
      {(
        inputProps: React.JSX.IntrinsicAttributes &
          React.ClassAttributes<HTMLInputElement> &
          React.InputHTMLAttributes<HTMLInputElement>,
      ) => (
        <div className="relative mt-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <PhoneIcon aria-hidden="true" className="h-5 w-5 text-skin-muted" />
          </div>
          <input
            {...inputProps}
            type="text"
            name="phone-number"
            autoComplete="tel"
            placeholder="Phone number"
            inputMode="tel"
            className="block w-full resize-none rounded-md bg-skin-hover py-1.5 pl-10 text-skin-base outline-none placeholder:text-skin-muted hover:border-skin-hover focus:border-2 focus:border-skin-accentSecondary focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm sm:leading-6"
          />
        </div>
      )}
    </InputMask>
  );
}

export default PhoneNumberInput;
