import { chatIcons } from "../layout/LayoutConfig";

function RightIconMenu({
  handleToggle,
  menuType,
}: {
  handleToggle: (menu: string) => void;
  menuType: string;
}) {
  return (
    <div className="sticky top-0 z-20 h-fit w-20 bg-skin-fill pb-4 pt-6">
      <nav className="mt-8">
        <ul className="flex flex-col items-center space-y-1">
          {chatIcons.map((item) => (
            <li key={item.name}>
              <button
                onClick={() => handleToggle(item.name)}
                className={
                  menuType === "meta" && item.name === "meta"
                    ? "group flex gap-x-3 rounded-md bg-skin-accent p-3 text-sm font-semibold leading-6 text-skin-base hover:bg-skin-hover"
                    : "group flex gap-x-3 rounded-md p-3 text-sm font-semibold leading-6 text-skin-base hover:bg-skin-hover"
                }
              >
                <item.icon aria-hidden="true" className="h-6 w-6 shrink-0" />
                <span className="sr-only">{item.name}</span>
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default RightIconMenu;
