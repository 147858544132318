import { useEffect } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { randomName } from "../../utils/randomNames";
import IconButton from "../shared/buttons/IconButton";
import SubmitButton from "../shared/buttons/SubmitButton";
import { PlusIcon } from "../shared/icons/icons";
import EditParameterItem from "./EditParameterItem";
import {
  createNestedObjectFromFormValues,
  ParameterFormValues,
  ToolParametersWithAdditionalPropertiesObject,
} from "./hooks/createParameterObjectHelper";
import { useToolContext } from "./hooks/ToolContext";

export type ToolParameters = Record<
  string,
  ToolParametersWithAdditionalPropertiesObject
>;

interface ToolParameterFormProps {
  defaultParameters?: ToolParameters;
  setIsEdit: (isEdit: boolean) => void;
}

function EditParameters({
  defaultParameters,
  setIsEdit,
}: ToolParameterFormProps) {
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ParameterFormValues>({
    defaultValues: {
      parameters: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "parameters",
  });

  const { updateTool, tool } = useToolContext();

  const onSubmit: SubmitHandler<ParameterFormValues> = (data) => {
    const object = createNestedObjectFromFormValues(data);

    updateTool({ id: tool?.id as string, parameters: object });
    // Process the data or send it to an API
    setIsEdit(false);
  };

  // Load the default parameters if provided
  useEffect(() => {
    if (defaultParameters) {
      const mappedParameters = Object.keys(defaultParameters).map((key) => ({
        name: key,
        type: defaultParameters[key].type,
        description: defaultParameters[key].description || "",
        default: defaultParameters[key].default,
        additionalProperties: defaultParameters[key]?.additionalProperties
          ? // @ts-ignore
            Object.keys(defaultParameters[key]?.additionalProperties).map(
              (nestedKey) => ({
                name: nestedKey,
                type: defaultParameters[key].additionalProperties![nestedKey]
                  .type,
                description:
                  defaultParameters[key].additionalProperties![nestedKey]
                    .description || "",
                default:
                  defaultParameters[key].additionalProperties![nestedKey]
                    .default,
              }),
            )
          : [],
      }));
      reset({ parameters: mappedParameters });
    }
  }, [defaultParameters, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      {fields.map((item, index) => {
        return (
          <EditParameterItem
            nestedName="parameters"
            item={item}
            index={index}
            watch={watch}
            remove={remove}
            control={control}
            register={register}
            errors={errors}
          />
        );
      })}

      <div className="max-w-md">
        <IconButton
          onClick={() =>
            append({
              name: randomName(),
              type: "string",
              description: "",
              default: "",
              additionalProperties: [],
            })
          }
        >
          <PlusIcon className="mr-2 h-5 w-5" />
          Add Parameter
        </IconButton>
      </div>

      <SubmitButton type="submit">Submit</SubmitButton>
    </form>
  );
}

export default EditParameters;
