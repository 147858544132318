import ToolGrid from "./ToolGrid";
import ToolsContainerHeader from "./ToolsContainerHeader";

function ToolsListContainer({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="sticky top-0 flex h-full max-h-screen max-w-2xl flex-col overflow-y-auto bg-skin-fill pt-6 shadow-lg">
      <ToolsContainerHeader setOpen={setOpen} />
      <div className="flex-grow overflow-y-auto">
        <ToolGrid />
      </div>
    </div>
  );
}

export default ToolsListContainer;
