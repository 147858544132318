import { Button } from "@material-tailwind/react";
import { useMemo } from "react";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { Schema } from "../../api/extractionSchema.type";
import { useExtractionSchemaContext } from "../fabrk/hooks/ExtractionSchemaContext";

import { useSearchParams } from "react-router-dom";
import { useLeadContext } from "../fabrk/hooks/LeadContext";
import AddButton from "../shared/forms/AddButton";
import { FabrkInput } from "../shared/forms/input/InputBase";
import EditSchemaItem from "./EditSchemaItem";

function EditSchema({
  schema,
  setIsEdit,
  toolId,
  name,
  id,
}: {
  schema: Schema[];
  setIsEdit: (value: boolean) => void;
  toolId: string;
  name?: string;
  id?: string;
}) {
  const [searchParams] = useSearchParams();

  const agentId = searchParams.get("agentId");

  const { updateSchema, createSchema } = useExtractionSchemaContext();
  const { lead } = useLeadContext();

  const activeSchema = useMemo(() => {
    return schema;
  }, [schema]);

  const defaultValues = activeSchema.map((item) => {
    return {
      id: item.id,
      //@ts-ignore
      type: item.type === "string" ? "text" : item.type,
      question: item.question,
      required: item.required,
    };
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: name,
      id,
      questions: defaultValues,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  function handleSetSelectedType(id: number, value: string) {
    setValue(`questions.${id}.type`, value);
  }
  const onSubmit: SubmitHandler<any> = (data: any) => {
    const values = {
      name: data.name,
      schema: data.questions.map((item: Schema, index: number) => {
        return {
          id: item.id ? item.id : index + 1,
          type: item.type,
          question: item.question,
          required: item.required,
        };
      }),
      companyId: lead?.company_id,
      agentId: agentId as string,
      id,
      toolId,
    };
    id ? updateSchema(values) : createSchema(values);
    setIsEdit(false);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-5 p-2">
        <div>
          <FabrkInput
            register={register}
            errors={errors}
            label="Name"
            name="name"
            required={false}
          />
        </div>

        <div className="flex flex-col gap-4">
          {fields?.map((item, index) => {
            return (
              <EditSchemaItem
                key={item.id}
                remove={remove}
                index={index}
                handleSetSelectedType={handleSetSelectedType}
                control={control}
              />
            );
          })}
          <div>
            <AddButton
              iconClass="h-4 w-4"
              color="green"
              onClick={() => {
                append({
                  id: "",
                  type: "text",
                  question: "",
                  required: false,
                });
              }}
            >
              Add question
            </AddButton>
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-end gap-2">
        <Button
          size="sm"
          variant="outlined"
          color="gray"
          onClick={() => setIsEdit(false)}
        >
          Cancel
        </Button>
        <Button size="sm" color="green" fullWidth={false} type="submit">
          {id ? "Update" : "Create"}
        </Button>
      </div>
    </form>
  );
}

export default EditSchema;
