import { UserGroup } from "../shared/icons/icons";

function ToolHeaderPills({
  agentUseCount,
  // leadAgentCount,
}: {
  agentUseCount?: number;
  // leadAgentCount?: number;
}) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex h-fit flex-row items-center justify-evenly gap-1 rounded-md bg-skin-fill p-1 text-skin-base">
        <UserGroup className="h-3 w-3" />
        <p className="text-xs">Agents</p>
        <p className="text-xs">{agentUseCount || 0}</p>
      </div>
      {/* <div className="flex h-fit flex-row items-center justify-evenly gap-1 rounded-md bg-skin-fill p-1 text-skin-base">
        <ChatBubbleWithWritingIcon className="h-3 w-3" />
        <p className="text-xs">Threads</p>
        <p className="text-xs">{leadAgentCount || 0}</p>
      </div> */}
    </div>
  );
}

export default ToolHeaderPills;
