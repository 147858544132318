/* eslint-disable react-hooks/exhaustive-deps */
import { useSession } from "@supabase/auth-helpers-react";
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation } from "react-query";
import { createFabrkCompany } from "../../../api/company.api";
import { createNewSession, getSessionById } from "../../../api/session.api";
import { SessionRow } from "../../../api/session.type";
import { getUserById } from "../../../api/user.api";
import { UserRow } from "../../../api/user.type";
import { randomName } from "../../../utils/randomNames";

export const SessionContextWrapper = (props: any) => {
  const [randomCompanyName] = useState(randomName());

  const [sessionId, setSessionId] = useState<string>("");

  const session = useSession();

  const [companyId, setCompanyId] = useState<string>("");

  const { mutate: createSession, isLoading } = useMutation(createNewSession, {
    onSuccess: async (res) => {
      setSessionId(res.id);
      localStorage.setItem("session_id", res.id);
    },
    onError(error: Error) {},
  });

  const { mutate: getSession, data: fabrkSession } = useMutation(
    getSessionById,
    {
      onSuccess: async (res) => {},
      onError(error: Error) {},
    },
  );

  const { mutate: createCompany } = useMutation(createFabrkCompany, {
    onSuccess: async (res) => {
      setCompanyId(res.id);
      getSession(sessionId);
    },
    onError(error: Error) {},
  });

  const { mutate: getUserCompany } = useMutation(getUserById, {
    onSuccess: async (res) => {
      setCompanyId(res?.users_companies[0]?.company_id);
    },
    onError(error: Error) {},
  });

  function createCompanyIfNeeded({
    leadId,
    name,
  }: {
    leadId: string;
    name: string;
  }) {
    if (!companyId) {
      createCompany({
        name,
        leadId,
      });
    }
  }

  useEffect(() => {
    if (!sessionId) {
      const foundSessionId = localStorage.getItem("session_id");
      if (foundSessionId) {
        setSessionId(foundSessionId);
      } else {
        if (!isLoading) {
          createSession();
        }
      }
    }
  }, []);

  useEffect(() => {
    if (sessionId) {
      getSession(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (
      fabrkSession &&
      fabrkSession.lead?.company_id &&
      !companyId &&
      !session
    ) {
      setCompanyId(fabrkSession.lead.company_id);
    }
  }, [fabrkSession]);

  useEffect(() => {
    if (session && session?.user?.id) {
      getUserCompany({ id: session?.user?.id as string });
    }
  }, [session]);

  useEffect(() => {
    if (companyId) {
      localStorage.setItem("company_id", companyId);
    }
  }, [companyId]);

  const value = {
    fabrkSession,
    companyId,
    randomCompanyName,
    createCompanyIfNeeded,
    getUserCompany,
  };

  return (
    <SessionContext.Provider value={value}>
      {props.children}
    </SessionContext.Provider>
  );
};

export const SessionContext = createContext({
  fabrkSession: {} as SessionRow | undefined,
  companyId: "",
  randomCompanyName: "",
  createCompanyIfNeeded: (props: { leadId: string; name: string }) => {},
  getUserCompany: {} as UseMutateFunction<
    UserRow,
    Error,
    {
      id: string;
    },
    unknown
  >,
});

export const useSessionContext = () => useContext(SessionContext);
