import { useState } from "react";

import {
  ChatBubbleOvalLeftIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { mapTypeToLabel } from "../../../api/agent.type";
import FabrkMenuDivider from "../../fabrk/layout/FabrkMenuDivider";
import IconButton from "../../shared/buttons/IconButton";
import BaseChip from "../../shared/chip/BaseChip";
import { EditIcon } from "../../shared/icons/icons";
import ShowMoreParagraph from "../../shared/typography/ShowMoreParagraph";
import { useAgentContext } from "../hooks/AgentContext";
import EditAgentName from "./EditAgentName";

function ViewAgentHeader() {
  const [isEdit, setIsEdit] = useState(false);
  const { activeAgent } = useAgentContext();

  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div className="lg:pr-4">
          <div className="relative overflow-hidden rounded-3xl bg-skin-base px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
            <img
              alt=""
              src={activeAgent?.image_path || "/images/robot.webp"}
              className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
            />

            <figure className="relative isolate">
              <figcaption className="mt-6 w-fit rounded-md bg-skin-hover px-4 leading-6">
                <strong className="text-xl font-bold text-skin-accent">
                  {activeAgent?.name}
                </strong>{" "}
              </figcaption>
            </figure>
          </div>
        </div>
        <div>
          <div className="w-full leading-7 text-skin-base/80">
            <div className="flex flex-row justify-between">
              <p className="font-semibold leading-7 text-skin-muted/80">
                Agent Details
              </p>
              <IconButton
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
              >
                <EditIcon className="h-5 w-5" />
              </IconButton>
            </div>
            <FabrkMenuDivider />

            {isEdit ? (
              <EditAgentName setIsEdit={setIsEdit} />
            ) : (
              <div>
                <div className="flex flex-row items-center justify-between">
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-skin-base sm:text-4xl">
                    {activeAgent?.name}
                  </h1>
                  {activeAgent?.type && (
                    <BaseChip>{mapTypeToLabel(activeAgent?.type)}</BaseChip>
                  )}
                </div>
                <div className="max-w-xl">
                  <p className="mt-6">{activeAgent?.goal}</p>
                </div>
                <div className="max-w-xl">
                  <ShowMoreParagraph
                    text={activeAgent?.system_prompt?.text as string}
                    maxLength={250}
                  />
                </div>
              </div>
            )}
          </div>
          <dl className="mt-10 grid grid-cols-2 gap-8 pt-10 sm:grid-cols-2">
            <div>
              <dt className="flex flex-row items-center gap-2 text-sm font-semibold leading-6 text-skin-base">
                <WrenchIcon className="h-4 w-4" /> Tool Count
              </dt>
              <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-skin-base">
                {activeAgent?.agent_ai_function?.length || 0}
              </dd>
            </div>
            <div>
              <dt className="flex flex-row items-center gap-2 text-sm font-semibold leading-6 text-skin-base">
                <ChatBubbleOvalLeftIcon className="h-4 w-4" /> Thread Count
              </dt>
              <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-skin-base">
                {activeAgent?.lead_agent?.length || 0}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default ViewAgentHeader;
