/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import {
  addCampaignTool,
  deleteAiCampaignFunction,
  getCampaignToolByCampaign,
} from "../../../api/agentTool.api";
import { CampaignToolRow } from "../../../api/agentTool.type";
import { createCompanyAiAgent } from "../../../api/companyTool.api";
import { createFileStoreTool } from "../../../api/fileStore.api";
import { AddFileStoreToTool, FileStoreRow } from "../../../api/fileStore.type";
import { ToolCreate, ToolRow } from "../../../api/tool.type";
import { useAlertContext } from "../../shared/alert/alertContext";
import { toolIcons } from "../config/ToolIcons";
import { useLeadAgentContext } from "./LeadAgentContext";

export const AgentToolContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const queryClient = useQueryClient();

  const { activeLeadAgent } = useLeadAgentContext();

  const [newAgentType, setNewAgentType] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");

  const [selectedTools, setSelectedTools] = useState<{
    [key: string]: boolean;
  }>({});

  const { mutate: getCampaignTools, data: agentTools } = useMutation(
    getCampaignToolByCampaign,
    {
      onSuccess: async (res) => {},
      onError(error: Error) {
        console.log(error.message);
      },
    },
  );

  const { mutate: createAgent } = useMutation(createCompanyAiAgent, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("agents");
      queryClient.invalidateQueries({ queryKey: ["agents", "leadAgents"] });
      getCampaignTools(activeLeadAgent?.agent_id as string);
      setAlertProps({
        message: "Agent created",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: addToolToAgent } = useMutation(addCampaignTool, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("agents");

      queryClient.invalidateQueries({ queryKey: ["agents", "leadAgents"] });
      getCampaignTools(activeLeadAgent?.agent_id as string);
      setAlertProps({
        message: "Tool added",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: addFile } = useMutation(createFileStoreTool, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries({ queryKey: ["agents", "leadAgents"] });
      getCampaignTools(activeLeadAgent?.agent_id as string);
      setAlertProps({
        message: "Tool created",
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error.message);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: removeAgent } = useMutation(deleteAiCampaignFunction, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries({ queryKey: ["agents", "leadAgents"] });

      setAlertProps({
        message: "Tool removed",
        color: "green",
      });
      getCampaignTools(activeLeadAgent?.agent_id as string);
    },
    onError(error: Error) {
      console.log(error.message);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    if (activeLeadAgent) {
      getCampaignTools(activeLeadAgent.agent_id as string);
    }
  }, [activeLeadAgent]);

  useEffect(() => {
    if (agentTools && toolIcons) {
      setSelectedTools(
        toolIcons?.reduce(
          (acc, agent) => {
            if (!agent) return acc;
            if (
              agentTools?.find(
                (a) =>
                  a.tool?.slug === agent?.slug || a.tool?.type === agent?.slug,
              )
            ) {
              acc[agent?.name] = true;
            } else {
              acc[agent?.name] = false;
            }
            return acc;
          },
          {} as { [key: string]: boolean },
        ),
      );
    }
  }, [agentTools]);

  const value = {
    createAgent,
    addToolToAgent,
    removeAgent,
    newAgentType,
    setNewAgentType,
    dialogTitle,
    setDialogTitle,
    addFile,
    agentTools,

    selectedTools,
  };

  return (
    <AgentToolContext.Provider value={value}>
      {props.children}
    </AgentToolContext.Provider>
  );
};

export const AgentToolContext = createContext({
  createAgent: {} as UseMutateFunction<ToolRow, Error, ToolCreate, unknown>,
  addToolToAgent: {} as UseMutateFunction<
    CampaignToolRow[],
    Error,
    {
      agentId: string;
      toolId: string;
    },
    unknown
  >,
  removeAgent: {} as UseMutateFunction<
    CampaignToolRow[],
    Error,
    {
      agentId: string;
      toolId: string;
    },
    unknown
  >,

  newAgentType: "",
  setNewAgentType: {} as React.Dispatch<React.SetStateAction<string>>,
  dialogTitle: "",
  setDialogTitle: {} as React.Dispatch<React.SetStateAction<string>>,
  addFile: {} as UseMutateFunction<
    FileStoreRow,
    Error,
    AddFileStoreToTool,
    unknown
  >,

  agentTools: [] as CampaignToolRow[] | undefined,

  selectedTools: {} as { [key: string]: boolean },
});

export const useAgentToolContext = () => useContext(AgentToolContext);
