/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  createCampaignExtractionSchema,
  createExtractionSchema,
  deleteCampaignExtractionSchema,
  getExtractionSchemaAgentByTool,
  getExtractionSchemas,
  updateExtractionSchema,
} from "../../../api/extractionSchema.api";
import {
  CreateExtractionSchema,
  ExtractionSchemaCampaignRow,
  ExtractionSchemaRow,
} from "../../../api/extractionSchema.type";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useToolContext } from "../../tools/hooks/ToolContext";
import {
  CreateCampaignExtractionSchema,
  CreateSchema,
  DeleteCampaignExtractionSchema,
} from "./ExtractionSchema.type";
import { useSessionContext } from "./FabrkSessionContext";
import { useLeadAgentContext } from "./LeadAgentContext";

export const ExtractionSchemaContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();

  const { setAlertProps } = useAlertContext();

  const { companyId } = useSessionContext();

  const { activeLeadAgent, refetchData } = useLeadAgentContext();

  const { handleGetTool } = useToolContext();

  const { data: schema, refetch } = useQuery({
    queryKey: "extractionSchema",
    queryFn: () => getExtractionSchemas(companyId as string),
    enabled: companyId ? true : false,
  });

  const [extractionSchemaCampaigns, setExtractionSchemaCampaigns] =
    useState<ExtractionSchemaCampaignRow[]>();

  const { mutate: addSchemaToCampaign } = useMutation(
    createCampaignExtractionSchema,
    {
      onSuccess: (data) => {
        getToolSchema(activeLeadAgent?.agent_id as string);
        refetchData();
        setAlertProps({
          message: "Schema added successfully",
          color: "green",
        });
      },
      onError: (error: any) => {
        setAlertProps({
          message: "There was an error fetching the schema: " + error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: getToolSchema, isLoading } = useMutation(
    getExtractionSchemaAgentByTool,
    {
      onSuccess: (data) => {
        setExtractionSchemaCampaigns(data);
      },
      onError: (error: any) => {
        setAlertProps({
          message: "There was an error fetching the schema: " + error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: removeFromCampaign } = useMutation(
    deleteCampaignExtractionSchema,
    {
      onSuccess: (data) => {
        refetch();
        refetchData();
        getToolSchema(activeLeadAgent?.agent_id as string);
        setAlertProps({
          message: "Schema removed successfully",
          color: "green",
        });
      },
      onError: (error: any) => {
        setAlertProps({
          message: "There was an error deleting the schema: " + error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: createSchema } = useMutation(createExtractionSchema, {
    onSuccess: () => {
      queryClient.invalidateQueries("leadAgents");
      handleGetTool();
      refetch();
      refetchData();
      setAlertProps({
        message: "Schema created successfully!",
        color: "green",
      });

      if (activeLeadAgent?.agent_id) {
        getToolSchema(activeLeadAgent?.agent_id as string);
      }
    },
    onError: (error: any) => {
      setAlertProps({
        message: "There was an error creating the schema: " + error.message,
        color: "red",
      });
    },
  });

  const { mutate: updateSchema } = useMutation(updateExtractionSchema, {
    onSuccess: () => {
      queryClient.invalidateQueries("leadAgents");
      handleGetTool();
      refetch();
      refetchData();
      setAlertProps({
        message: "Schema updated successfully!",
        color: "green",
      });

      if (activeLeadAgent?.agent_id) {
        getToolSchema(activeLeadAgent?.agent_id as string);
      }
    },
    onError: (error: any) => {
      setAlertProps({
        message: "There was an error updating the schema: " + error.message,
        color: "red",
      });
    },
  });

  const value = {
    schema,
    createSchema,
    extractionSchemaCampaigns,
    addSchemaToCampaign,
    removeFromCampaign,
    getToolSchema,
    loading: isLoading,
    updateSchema,
  };

  return (
    <ExtractionSchemaContext.Provider value={value}>
      {children}
    </ExtractionSchemaContext.Provider>
  );
};

export const ExtractionSchemaContext = createContext({
  schema: {} as ExtractionSchemaRow[] | undefined,
  createSchema: {} as CreateSchema,
  extractionSchemaCampaigns: {} as ExtractionSchemaCampaignRow[] | undefined,
  addSchemaToCampaign: {} as CreateCampaignExtractionSchema,
  removeFromCampaign: {} as DeleteCampaignExtractionSchema,
  getToolSchema: {} as UseMutateFunction<
    ExtractionSchemaCampaignRow[],
    any,
    string,
    unknown
  >,
  loading: false,
  updateSchema: {} as UseMutateFunction<
    ExtractionSchemaRow,
    any,
    CreateExtractionSchema,
    unknown
  >,
});

export const useExtractionSchemaContext = () =>
  useContext(ExtractionSchemaContext);
