import { Anthropic } from "../../shared/icons/anthropicSvg";
import { GoogleLogo } from "../../shared/icons/googleSvg";
import { MetaLogo } from "../../shared/icons/metaSvg";
import { OpenAi } from "../../shared/icons/openAiSvg";

export type ModelMenuItem = {
  provider: string;
  models: string[];
  icon: JSX.Element;
  slug: string;
  description: string;
};

export const models = [
  {
    provider: "OpenAI",
    models: ["GPT-4o,GPT-4"],
    icon: <OpenAi className="h-6 w-6" />,
    slug: "openai",
    description: "GTP-4 and GPT-4o models supported.",
  },
  {
    provider: "Anthropic",
    models: ["Claude 3 Opus"],
    icon: <Anthropic className="h-6 w-6" />,
    slug: "anthropic",
    description: "Support for Claude 3 Opus model coming soon.",
  },
  {
    provider: "Google",
    models: ["Gemini Ultra"],
    icon: <GoogleLogo className="h-6" />,
    slug: "google",
    description: "Support for Gemini Ultra model coming soon.",
  },
  {
    provider: "Meta",
    models: ["Llama 3"],
    icon: <MetaLogo className="h-6" />,
    slug: "meta",
    description: "Support for Llama 3 model coming soon.",
  },
];
