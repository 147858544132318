export const FabrkTextArea = ({
  register,
  errors,
  label,
  name,
  required = true,
  ...props
}: {
  register: any;
  errors: any;
  label: string;
  name: string;
  required?: boolean;
}) => {
  return (
    <div className="mb-2">
      <textarea
        label={label}
        variant="outlined"
        {...register(name, { required })}
        className="w-full rounded-md border-0 bg-skin-hover py-1 text-skin-base placeholder:text-skin-muted focus:border-2 focus:border-skin-accentSecondary focus:text-skin-base focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent sm:text-sm sm:leading-6"
        {...props}
      />
    </div>
  );
};
