import React, { createContext, useContext, useState, useEffect } from "react";

// DarkModeProvider component
export const DarkModeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Check local storage for theme preference
    const darkMode = localStorage.getItem("darkMode");
    const prefersDarkMode = darkMode === null || darkMode === "true"; // Default to dark mode if not set

    setIsDarkMode(prefersDarkMode);

    // Set the "data-theme" attribute on the html element
    if (prefersDarkMode) {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.setAttribute("data-theme", "light");
    }
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("darkMode", newMode.toString());

      // Update the "data-theme" attribute
      if (newMode) {
        document.documentElement.setAttribute("data-theme", "dark");
      } else {
        document.documentElement.setAttribute("data-theme", "light");
      }

      console.log(newMode ? "Dark mode enabled" : "Light mode enabled");

      return newMode;
    });
  };

  // Provide the dark mode state and toggle function to the context
  const value = {
    isDarkMode,
    toggleDarkMode,
  };

  return (
    <DarkModeContext.Provider value={value}>
      {children}
    </DarkModeContext.Provider>
  );
};

// Create the DarkModeContext
const DarkModeContext = createContext({
  isDarkMode: false,
  toggleDarkMode: () => {},
});

// Custom hook to use the DarkModeContext
export const useDarkModeContext = () => {
  return useContext(DarkModeContext);
};
