import { PencilIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import { useState } from "react";
import { formatTimeStampToHumanReadableShortDateTime } from "../../../utils/parseTimeStamp";
import FabrkMenuDivider from "../../fabrk/layout/FabrkMenuDivider";
import IconButton from "../../shared/buttons/IconButton";
import SubmitButton from "../../shared/buttons/SubmitButton";
import { InputBase, TextArea } from "../../shared/forms/input/InputBase";
import {
  DataValueBase,
  HeadingBase,
  LabelBase,
} from "../../shared/typography/TypographyBase";
import { useAgentContext } from "../hooks/AgentContext";

function AgentDetails() {
  const [isEditing, setIsEditing] = useState(false);

  const { updateAgent, activeAgent, updatePrompt } = useAgentContext();

  const [agentName, setAgentName] = useState(activeAgent?.name || "");

  const [instructions, setInstructions] = useState(
    activeAgent?.system_prompt?.text || "",
  );

  const [goal, setGoal] = useState(activeAgent?.goal || "");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (instructions !== activeAgent?.system_prompt?.text) {
      updatePrompt({
        id: activeAgent?.system_prompt?.id as string,
        text: instructions,
      });
    }

    if (agentName !== activeAgent?.name || goal !== activeAgent?.goal) {
      updateAgent({
        id: activeAgent?.id as string,
        ...(agentName !== activeAgent?.name && {
          name: agentName,
        }),
        ...(goal !== activeAgent?.goal && {
          goal: goal,
        }),
      });
    }

    setIsEditing(false);
  };

  return (
    <div>
      <div className="flex flex-row justify-between">
        <HeadingBase>Details</HeadingBase>
        <IconButton
          onClick={() => {
            setIsEditing(!isEditing);
            setAgentName(activeAgent?.name || "");
            setInstructions(activeAgent?.system_prompt?.text || "");
          }}
        >
          <PencilIcon className="h-4 w-4" />
        </IconButton>
      </div>
      <FabrkMenuDivider />
      <form onSubmit={handleSubmit}>
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex flex-row justify-between">
            <div>
              <LabelBase>Name</LabelBase>
              {isEditing ? (
                <InputBase value={agentName} setValue={setAgentName} />
              ) : (
                <DataValueBase>{activeAgent?.name}</DataValueBase>
              )}
            </div>
            <div>
              <LabelBase>Created</LabelBase>
              <DataValueBase>
                {formatTimeStampToHumanReadableShortDateTime(
                  activeAgent?.created_at,
                )}
              </DataValueBase>
            </div>
          </div>
          <div>
            <Tooltip
              placement="top"
              content="This is the goal of this agent. This helps you to keep track of the purpose of this agent. This is not passed into the AI model."
              className="max-w-md bg-skin-hover text-skin-base"
            >
              <div>
                <LabelBase>Explanation</LabelBase>
              </div>
            </Tooltip>
            {isEditing ? (
              <TextArea value={goal} setValue={setGoal} />
            ) : (
              <DataValueBase>{activeAgent?.goal}</DataValueBase>
            )}
          </div>
          <div>
            <Tooltip
              placement="top"
              content="Instructions are the text that the AI model will use to generate responses. You can edit this text to change the behavior of the AI model."
              className="max-w-md bg-skin-hover text-skin-base"
            >
              <div>
                <LabelBase>Instructions</LabelBase>
              </div>
            </Tooltip>
            {isEditing ? (
              <TextArea value={instructions} setValue={setInstructions} />
            ) : (
              <DataValueBase>{activeAgent?.system_prompt?.text}</DataValueBase>
            )}
          </div>
          {isEditing && (
            <div className="flex justify-end">
              <div>
                <SubmitButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={
                    instructions === activeAgent?.system_prompt?.text &&
                    agentName === activeAgent?.name &&
                    goal === activeAgent?.goal
                  }
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default AgentDetails;
