import { Checkbox } from "@material-tailwind/react";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { availableQuestionTypes } from "../../api/extractionSchema.type";
import DeleteButton from "../shared/forms/DeleteButton";
import GenericSelect from "../shared/forms/GenericSelect";
import { InputBase } from "../shared/forms/input/InputBase";

type EditSchemaItemProps = {
  index: number;
  control: Control<
    {
      name: string | undefined;
      id: string | undefined;
      questions: {
        id: string;
        type: string;
        question: string;
        required: boolean;
      }[];
    },
    any
  >;
  remove: (index: number) => void;
  handleSetSelectedType: (index: number, value: string) => void;
};

function EditSchemaItem({
  index,
  control,
  remove,
  handleSetSelectedType,
}: EditSchemaItemProps & React.HTMLProps<HTMLDivElement>) {
  return (
    <div className="relative grid grid-cols-4 items-start gap-1 p-1">
      <div className="absolute -right-10 top-1">
        <DeleteButton onClick={() => remove(index)} />
      </div>
      <div className="col-span-3 items-center">
        <Controller
          render={({ field }) => (
            <div>
              <InputBase
                placeholder="Question"
                value={field.value}
                setValue={field.onChange}
              />
            </div>
          )}
          name={`questions.${index}.question`}
          control={control}
        />
      </div>
      <div className="items-center">
        <Controller
          render={({ field }) => {
            return (
              <div>
                <GenericSelect
                  values={availableQuestionTypes.map((type) => {
                    return {
                      value: type,
                      label: type,
                    };
                  })}
                  setSelected={(value) => handleSetSelectedType(index, value)}
                  selected={field.value}
                />
              </div>
            );
          }}
          name={`questions.${index}.type`}
          control={control}
        />

        <Controller
          render={({ field }) => (
            <div className="mb-2">
              <Checkbox
                label="Required"
                crossOrigin={"true"}
                {...field}
                checked={field.value ? true : false}
                value={field.value ? "true" : "false"}
              />
            </div>
          )}
          name={`questions.${index}.required`}
          control={control}
        />
      </div>
    </div>
  );
}

export default EditSchemaItem;
