import { Typography } from "@material-tailwind/react";
import Markdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import { SmsMessage } from "../../../api/sms.type";
import { formatTimeStampToHumanReadableDateTimeSeconds } from "../../../utils/parseTimeStamp";
import MessageMetadataContainer from "./metadata/MessageMetadataContainer";

function AssistantMessageChatItem({
  message,
  showMetadata,
  userMessageId,
}: {
  message: SmsMessage;
  showMetadata?: boolean;
  userMessageId?: string;
}) {
  return (
    <div className="flex w-full flex-col justify-between gap-4">
      <div className="p-2 text-skin-base">
        <Markdown rehypePlugins={[rehypeHighlight, remarkGfm]}>
          {message.content}
        </Markdown>
        {message.created_at && (
          <Typography className="text-xs text-skin-base">
            {formatTimeStampToHumanReadableDateTimeSeconds(message.created_at)}
          </Typography>
        )}
      </div>
      <MessageMetadataContainer
        showMetadata={showMetadata}
        message={message}
        userMessageId={userMessageId}
      />
    </div>
  );
}

export default AssistantMessageChatItem;
