import AddToolModel from "../../agent/settings/AddToolModel";
import GenericDialog from "../../shared/dialog/GenericDialog";

function CreateOrUpdateToolDialog({
  dialogOpen,
  setDialogOpen,
  dialogTitle,
}: {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  dialogTitle: string;
}) {
  return (
    <GenericDialog
      title={dialogTitle}
      open={dialogOpen}
      setOpen={setDialogOpen}
    >
      <AddToolModel setDialogOpen={setDialogOpen} />
    </GenericDialog>
  );
}

export default CreateOrUpdateToolDialog;
