import AgentSettingsMenu from "./AgentSettingsMenu";
import ToolsListContainer from "./ToolsListContainer";

function MenuContainer({
  menuType,
  setOpen,
}: {
  menuType: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <>
      {menuType === "tools" && <ToolsListContainer setOpen={setOpen} />}
      {menuType === "settings" && <AgentSettingsMenu setOpen={setOpen} />}
    </>
  );
}

export default MenuContainer;
