function BaseChip({
  children,
  ...props
}: {
  children: React.ReactNode;
  [x: string]: any;
}) {
  return (
    <div
      {...props}
      className="line-clamp-1 inline-flex items-center rounded-md bg-skin-hover/65 px-2 py-1 text-xs font-medium text-skin-base shadow-sm ring-1 ring-inset ring-blue-gray-400/10"
    >
      {children}
    </div>
  );
}

export default BaseChip;
