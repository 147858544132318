export function getNextItemInObject(obj: any, key: string) {
  const keys = Object.keys(obj);
  const index = obj.findIndex((item: any) => item.name === key);
  const nextKey = keys[index + 1];
  const next = obj[nextKey];

  return next;
}

export function uniqueStrings(strings: string[]): string[] {
  return Array.from(new Set(strings));
}

export function safeJsonStringify(
  fallback: string,
  value?: string | null,
): string {
  try {
    if (!value) {
      return fallback;
    }
    // Try to parse and pretty print the JSON
    return JSON.stringify(JSON.parse(value), null, 2);
  } catch (error) {
    // If parsing fails, return the fallback string
    return fallback;
  }
}
