import { useQuery } from "react-query";
import { getPublicTools } from "../../../api/tool.api";

export function useLoadPublicTools() {
  const {
    data: publicTools,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: "publicAiTools",
    queryFn: () => getPublicTools(),
  });

  return {
    isLoading,
    publicTools,
    error,
    refetch,
  };
}
