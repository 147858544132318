import { useQuery } from "react-query";
import { getPublicActions } from "../../../api/action.api";

export function useLoadActions() {
  const {
    data: actions,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: "actions",
    queryFn: () => getPublicActions(),
  });

  return {
    isLoading,
    actions,
    error,
    refetch,
  };
}
