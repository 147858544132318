import { StarIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import FabrkMenuDivider from "../../components/fabrk/layout/FabrkMenuDivider";
import { useAlertContext } from "../../components/shared/alert/alertContext";
import IconButton from "../../components/shared/buttons/IconButton";
import MetadataButton from "../../components/shared/buttons/MetadataButton";
import NewAgentButton from "../../components/shared/buttons/NewAgentButton";
import SubmitButton from "../../components/shared/buttons/SubmitButton";
import {
  CenteredNarrowContainer,
  ColumnContainer,
  FullWidthContainer,
  ThreeColumnContainer,
} from "../../components/shared/containers/Containers";
import FloatingAlert from "../../components/shared/forms/FloatingAlert";
import LoadingSpinner from "../../components/shared/forms/LoadingSpinner";
import { TextArea } from "../../components/shared/forms/input/InputBase";
import {
  DataValueBase,
  HeadingBase,
  LabelBase,
} from "../../components/shared/typography/TypographyBase";

function DesignContainer() {
  const [value, setValue] = useState("");
  const { setAlertProps } = useAlertContext();

  function handleSubmit(e: any) {
    console.log("submitting", value);
    setValue("");
  }

  function triggerAlert() {
    setAlertProps({
      message: "This is an alert",
      color: "green",
    });
  }

  return (
    <div>
      <div className="m-10"></div>
      <FullWidthContainer>
        <HeadingBase>Full width container</HeadingBase>
        <div className="m-10"></div>
        <FabrkMenuDivider />
        <div className="m-10"></div>
        <HeadingBase>Colors</HeadingBase>
        <ThreeColumnContainer>
          <div className="h-12 rounded-md bg-skin-fill p-2">
            <p className="text-center text-skin-base">Fill BG, Base text</p>
          </div>
          <div className="h-12 rounded-md bg-skin-muted p-2">
            <p className="text-center text-skin-inverse">
              Muted BG, inverse text
            </p>
          </div>
          <div className="h-12 rounded-md bg-skin-accent p-2">
            <p className="text-center text-skin-inverse">
              Accent BG, inverse text
            </p>
          </div>
          <div className="h-12 rounded-md bg-skin-accentSecondary p-2">
            <p className="text-center text-skin-inverse">
              Secondary Accent BG, inverse text
            </p>
          </div>
        </ThreeColumnContainer>
      </FullWidthContainer>
      <div className="m-10"></div>
      <FabrkMenuDivider />
      <div className="m-10"></div>
      <FullWidthContainer>
        <ThreeColumnContainer>
          <HeadingBase>Buttons</HeadingBase>
          <MetadataButton onClick={() => console.log("metadata button")}>
            Metadata button
          </MetadataButton>
          <div>
            <HeadingBase>Add button: </HeadingBase>
            <NewAgentButton />
          </div>
          <div>
            <LabelBase>Icon button: </LabelBase>
            <IconButton>
              <StarIcon className="h-4 w-4" />
            </IconButton>
          </div>
          <SubmitButton onClick={() => console.log("submit button")} />
          <LoadingSpinner />
        </ThreeColumnContainer>
      </FullWidthContainer>
      <div className="m-10"></div>
      <FabrkMenuDivider />
      <div className="m-10"></div>
      <CenteredNarrowContainer>
        <HeadingBase>This is a centered narrow container</HeadingBase>
        <FabrkMenuDivider />
        <ColumnContainer>
          <HeadingBase>This is a column container with a 2 gap</HeadingBase>
          <FabrkMenuDivider />
          <HeadingBase>Heading base</HeadingBase>

          <LabelBase>Label base</LabelBase>
          <DataValueBase>Data value base</DataValueBase>
          <FabrkMenuDivider />
        </ColumnContainer>
      </CenteredNarrowContainer>
      <div className="m-10"></div>
      <FabrkMenuDivider />
      <div className="m-10"></div>
      <CenteredNarrowContainer>
        <ColumnContainer>
          <HeadingBase>Forms</HeadingBase>
          <TextArea
            value={value}
            setValue={setValue}
            handleSubmit={handleSubmit}
            placeholder="Type something here"
          />
          <SubmitButton onClick={handleSubmit} />
        </ColumnContainer>
        <div className="m-10"></div>

        <SubmitButton onClick={triggerAlert}>Show alert</SubmitButton>
        <FloatingAlert />
      </CenteredNarrowContainer>
    </div>
  );
}

export default DesignContainer;
