import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  PlusIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import { useSession } from "@supabase/auth-helpers-react";
import { useLeadAgentContext } from "../../fabrk/hooks/LeadAgentContext";
import { useAgentContext } from "../hooks/AgentContext";

function HeaderMenu({
  setDialogOpen,
  setDialogTitle,
  personaEnabled,
}: {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogTitle: React.Dispatch<React.SetStateAction<string>>;
  personaEnabled: boolean;
}) {
  const session = useSession();

  const { activeAgent } = useAgentContext();

  const { createNewLeadAgent } = useLeadAgentContext();

  function handleNewThread() {
    createNewLeadAgent({ agentId: activeAgent?.id as string });
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <MenuButton className="relative inline-flex items-center rounded-md bg-skin-fill p-2 text-skin-base shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-skin-hover">
        <span className="absolute -inset-1" />
        <span className="sr-only">Open options menu</span>
        <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
      </MenuButton>
      <MenuItems
        transition
        className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-skin-fill shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="py-1">
          <MenuItem>
            <a
              href={`/agent/${activeAgent?.id}`}
              className="block px-4 py-2 text-sm text-skin-base/80 data-[focus]:bg-skin-hover data-[focus]:text-skin-base/80"
            >
              View Agent Page
            </a>
          </MenuItem>
          <MenuItem>
            <button
              className="flex w-full flex-row items-center gap-2 px-4 py-2 text-sm text-skin-base data-[focus]:bg-skin-hover data-[focus]:text-skin-base/80"
              onClick={handleNewThread}
              disabled={!session}
            >
              <PlusIcon className="h-4 w-4" /> New Thread
            </button>
          </MenuItem>
          <Tooltip
            placement="bottom"
            content="Creates a conversation with a persona agent and this agent. To create a persona agent, create an agent and set the type to persona."
            className="bg-skin-hover text-skin-base"
          >
            <MenuItem>
              <button
                className="flex w-full flex-row items-center gap-2 px-4 py-2 text-sm text-skin-base data-[focus]:bg-skin-hover data-[focus]:text-skin-base/80"
                onClick={() => {
                  setDialogTitle("Run Persona");
                  setDialogOpen(true);
                }}
                disabled={!personaEnabled}
              >
                <UserCircleIcon className="h-4 w-4" /> Run Persona
              </button>
            </MenuItem>
          </Tooltip>
        </div>
      </MenuItems>
    </Menu>
  );
}

export default HeaderMenu;
