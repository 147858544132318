import { useQuery } from "react-query";
import { getDemoAgents } from "../../../api/agent.api";

export function useLoadPublicAgents() {
  const {
    data: publicAgents,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: "publicAgents",
    queryFn: () => getDemoAgents(),
  });

  return {
    isLoading,
    publicAgents,
    error,
    refetch,
  };
}
