import { Typography } from "@material-tailwind/react";
import LoadingSpinner from "../LoadingSpinner";
import { useFileUploadContext } from "../hooks/fileUploadContext";

function FileUploadedIndicator() {
  const { loading, fileName } = useFileUploadContext();
  return (
    <div className="file-uploaded-indicator">
      {fileName && (
        <div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className="w-fit p-1 px-2 text-sm text-skin-base">
              <Typography>Uploaded file: {fileName}</Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default FileUploadedIndicator;
