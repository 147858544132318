import { SubmitHandler, useForm } from "react-hook-form";
import { FabrkInput } from "../shared/forms/input/InputBase";
import { useToolContext } from "./hooks/ToolContext";
import { Button } from "@material-tailwind/react";

type ToolNameFormValues = {
  name: string;
  description: string;
};

function EditToolName({ setIsEdit }: { setIsEdit: (value: boolean) => void }) {
  const { tool, updateTool } = useToolContext();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      name: tool?.name || "",
      description: tool?.description || "",
    },
  });

  const onSubmit: SubmitHandler<ToolNameFormValues> = (
    data: ToolNameFormValues,
  ) => {
    const values = {
      ...(data.name && { name: data.name }),
      ...(data.description && { description: data.description }),
      id: tool?.id as string,
    };

    updateTool(values);

    setIsEdit(false);
    reset();
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5 p-2">
          <FabrkInput
            register={register}
            errors={errors}
            label="Name"
            name="name"
            required={true}
          />
          <FabrkInput
            register={register}
            errors={errors}
            label="Description"
            name="description"
            required={false}
          />
        </div>
        <div className="mt-6 flex justify-end gap-2">
          <Button
            size="sm"
            variant="outlined"
            color="gray"
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            color="green"
            fullWidth={false}
            type="submit"
            disabled={!isDirty}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditToolName;
