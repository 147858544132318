import { AgentRow } from "../../api/agent.type";
import ThreadNavItemWithSubList from "./ThreadNavItemWithSubList";

interface AgentThreadListProps {
  activeAgent: AgentRow | undefined;
  agents?: AgentRow[];
}

function AgentThreadList({ activeAgent, agents }: AgentThreadListProps) {
  return (
    <>
      {agents && agents.length > 0 && (
        <ul>
          {agents
            ?.filter((a) => a.id !== activeAgent?.id)
            .map((a) => {
              const isActive = activeAgent?.id === a.id;

              return (
                <ThreadNavItemWithSubList
                  key={a.id}
                  agent={a}
                  isActive={isActive}
                />
              );
            })}
        </ul>
      )}
    </>
  );
}

export default AgentThreadList;
