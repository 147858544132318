import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { ExtractionResponseRow } from "./extractionResponse.type";

export function getExtractionResponses(): Promise<ExtractionResponseRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.extractionResponses;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<ExtractionResponseRow[]>(url, request);
}

export function getExtractionResponsesByMessageId(messageId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.extractionResponses +
    "/message/" +
    messageId;

  const request = setHeaderOptions({ method: "GET" });

  return fetchData<ExtractionResponseRow[]>(url, request);
}
