import { Typography } from "@material-tailwind/react";

function LandingPageTitle() {
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl sm:text-center">
        <Typography variant="h1" className="text-center text-skin-base">
          The Worlds First{" "}
          <span className="text-skin-accent">Agent Engine</span>
        </Typography>
      </div>
    </div>
  );
}

export default LandingPageTitle;
