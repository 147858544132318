import { z } from "zod";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";

/*
Example form: https://tailwindui.com/components/application-ui/data-display/description-lists
*/

function ToolParametersForm({ params }: { params: Record<string, any> }) {
  if (!params) {
    return null;
  }
  const p = z.object(params);

  type P = z.infer<typeof p>;

  const schemaKeys = Object.keys(p.shape) as (keyof P)[];

  return (
    <dl className="mt-4 divide-y divide-skin-hover">
      <div className="px-4 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
        <dt>Name</dt>
        <dd>Type</dd>
        <dd>Description</dd>
        <dd>Default</dd>
      </div>
      {schemaKeys &&
        schemaKeys.map((param: string, index: number) => {
          const value = params[param];
          if (!value) {
            return null;
          }

          const className =
            value.type === "object" ? "bg-skin-hover p-2 rounded-lg " : "";
          return (
            <div className={className}>
              <div
                className="px-4 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0"
                key={index}
              >
                <dt className="text-sm font-medium leading-6 text-skin-base">
                  {snakeCaseToTitleCase(param)}
                </dt>

                <dd className="mt-1 text-sm leading-6 text-skin-base/80 sm:mt-0">
                  {value?.type}
                </dd>
                <dd className="mt-1 text-sm leading-6 text-skin-base/80 sm:mt-0">
                  {value?.description || "No description provided"}
                </dd>
                {value.default && (
                  <dd className="mt-1 max-w-40 break-words text-sm leading-6 text-skin-base/80 sm:mt-0">
                    {value?.default}
                  </dd>
                )}
              </div>
              <div className="ml-4">
                {value.type === "object" && (
                  <ToolParametersForm params={value.additionalProperties} />
                )}
              </div>
            </div>
          );
        })}
    </dl>
  );
}
export default ToolParametersForm;
