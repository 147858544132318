import { useSession } from "@supabase/auth-helpers-react";
import LoginSignupButtons from "../fabrk/layout/loginSignup/LoginSignupButtons";
import UserDetails from "../fabrk/layout/threadMenu/UserDetails";

function ProfileMenu() {
  const session = useSession();

  return (
    <div className="flex items-center gap-x-4 lg:gap-x-6">
      {session ? <UserDetails /> : <LoginSignupButtons />}
    </div>
  );
}

export default ProfileMenu;
