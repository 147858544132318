import { Route, Routes } from "react-router-dom";
import "./App.css";
import ActionGridContainer from "./components/action/ActionGridContainer";
import AgentsContainer from "./pages/agents/AgentsContainer";
import DesignContainer from "./pages/design/DesignContainer";
import DiscoverAgentsContainer from "./pages/discover/DiscoverAgentsContainer";
import DiscoverToolsContainer from "./pages/discover/DiscoverToolsContainer";
import HomeContainer from "./pages/home/HomeContainer";
import ToolsContainer from "./pages/tools/ToolsContainer";
import ViewAgentContainer from "./pages/agent/ViewAgentContainer";
import ViewToolContainer from "./pages/tool/ViewToolContainer";

function App() {
  return (
    <Routes>
      <Route path="*" element={<ActionGridContainer />} />
      <Route path="/discover/agents" element={<DiscoverAgentsContainer />} />
      <Route path="/discover/tools" element={<DiscoverToolsContainer />} />
      <Route path="/agents" element={<AgentsContainer />} />
      <Route path="/tools" element={<ToolsContainer />} />
      <Route path="/tool/:id" element={<ViewToolContainer />} />
      <Route path="/agent/:agentId" element={<ViewAgentContainer />} />

      <Route path="/t" element={<HomeContainer />} />
      <Route path="/t/*" element={<HomeContainer />} />

      <Route path="/a/:agentId/t/:lcId" element={<HomeContainer />} />
      <Route path="/a/:agentId" element={<HomeContainer />} />

      <Route path="/design" element={<DesignContainer />} />
    </Routes>
  );
}
export default App;
