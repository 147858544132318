/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { createAgent, forkAgent } from "../../../api/agent.api";
import { AgentRow } from "../../../api/agent.type";
import {
  addLeadToAgent,
  createLeadAgent,
  getLeadAgentsByCompany,
  getLeadAgentsByLeadId,
} from "../../../api/leadAgent.api";
import { LeadAgentRow } from "../../../api/leadAgent.type";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useSessionContext } from "./FabrkSessionContext";
import { useLeadContext } from "./LeadContext";
import { useNavigate } from "react-router-dom";

export const LeadAgentContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const { setAlertProps } = useAlertContext();

  const { companyId } = useSessionContext();

  const queryClient = useQueryClient();

  const { lead } = useLeadContext();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [globalLoading, setGlobalLoading] = useState(false);

  const [activeLeadAgent, setActiveLeadAgent] = useState<LeadAgentRow | null>();

  // These are leadAgents that match the user's company. These are the agents that should be fully accessible to the user.
  const {
    data: companyLeadAgents,
    isLoading: isLoadingLeadAgents,
    refetch: refetchCompanyLeadAgents,
  } = useQuery({
    queryFn: async () => await getLeadAgentsByCompany(companyId as string),
    queryKey: ["companyLeadAgents", companyId],
    enabled: companyId ? true : false,
    onSettled: () => {
      setGlobalLoading(false);
    },
  });

  // These are the lead agents that match the current lead. These will have overlapping data with the companyLeadAgents. However, they will include leadAgents that were created by an action and not the agent will not be fully accessible to the user without forking.
  const {
    data: leadAgents,
    isLoading,
    refetch: refetchLeadAgents,
  } = useQuery({
    queryFn: async () => await getLeadAgentsByLeadId(lead?.id as string),
    queryKey: ["leadAgents", lead?.id],
    enabled: lead?.id ? true : false,
    onSettled: () => {
      setGlobalLoading(false);
    },
  });

  const refetchData = () => {
    refetchCompanyLeadAgents();
    refetchLeadAgents();
  };

  const [newLeadAgent, setNewLeadAgent] = useState<LeadAgentRow>();

  const { mutate: addLead, isLoading: newLeadAgentLoading } = useMutation(
    addLeadToAgent,
    {
      onSuccess: async (res) => {
        setNewLeadAgent(res);
        queryClient.invalidateQueries("leadAgents");
        queryClient.invalidateQueries("companyAgents");
      },
      onError(error: Error) {
        setErrorMessage(error.message);
      },
      onSettled: () => {
        setTimeout(() => {
          setNewLeadAgent(undefined);
        }, 1000);
      },
    },
  );

  const { mutate: createNewLeadAgent, isLoading: loading } = useMutation(
    createLeadAgent,
    {
      onSuccess: async (res) => {
        setNewLeadAgent(res);
        queryClient.invalidateQueries("leadAgents");
        queryClient.invalidateQueries("companyAgents");
      },
      onError(error: Error) {
        setErrorMessage(error.message);
      },
      onSettled: () => {
        setTimeout(() => {
          setNewLeadAgent(undefined);
        }, 1000);
      },
    },
  );

  const { mutate: fork } = useMutation(forkAgent, {
    onSuccess: async () => {
      setAlertProps({
        message: "Agent forked",
        color: "green",
      });
      queryClient.invalidateQueries("leadAgents");
    },
    onError(error: Error) {
      setErrorMessage(error.message);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: newAgent } = useMutation(createAgent, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("leadAgents");

      refetchData();
      navigate(`/a/${res.agent_id}`);
    },
    onError(error: Error) {},
  });

  function handleCreateNewAgent() {
    newAgent({
      company_id: companyId,
      lead_id: lead?.id,
    });
  }

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    }
  }, [errorMessage]);

  // useEffect(() => {
  //   if (leadAgents && leadAgents.length > 0 && !activeLeadAgent) {
  //     navigate(`/a/${leadAgents[0].agent_id}/t/${leadAgents[0].id}`);
  //   }
  // }, []);

  const value = {
    companyLeadAgents,
    leadAgents,
    activeLeadAgent,
    handleCreateNewAgent,

    loading:
      isLoading ||
      globalLoading ||
      newLeadAgentLoading ||
      loading ||
      isLoadingLeadAgents,
    setGlobalLoading,
    errorMessage,
    setErrorMessage,
    addLead,
    setActiveLeadAgent,
    refetchData,
    fork,
    newLeadAgent,
    createNewLeadAgent,
  };

  return (
    <LeadAgentContext.Provider value={value}>
      {children}
    </LeadAgentContext.Provider>
  );
};

export const LeadAgentContext = createContext({
  leadAgents: {} as LeadAgentRow[] | undefined,
  companyLeadAgents: {} as LeadAgentRow[] | undefined,
  activeLeadAgent: {} as LeadAgentRow | null | undefined,

  handleCreateNewAgent: {} as () => void,

  loading: false,
  setGlobalLoading: {} as (loading: boolean) => void,
  errorMessage: "",
  setErrorMessage: {} as (message: string) => void,
  addLead: {} as UseMutateFunction<
    LeadAgentRow,
    Error,
    {
      agentId: string;
      leadId: string;
    },
    unknown
  >,
  setActiveLeadAgent: {} as React.Dispatch<
    React.SetStateAction<LeadAgentRow | null | undefined>
  >,
  refetchData: {} as () => void,

  fork: {} as UseMutateFunction<
    AgentRow,
    Error,
    {
      agentId: string;
      companyId: string;
      leadId: string;
    },
    unknown
  >,
  newLeadAgent: {} as LeadAgentRow | undefined,
  createNewLeadAgent: {} as UseMutateFunction<
    LeadAgentRow,
    Error,
    {
      agentId: string;
    },
    unknown
  >,
});

export const useLeadAgentContext = () => useContext(LeadAgentContext);
