import clsx from "clsx";
import { SmsMetadataRow } from "../../../../api/smsMetadata.type";
import BaseChip from "../../../shared/chip/BaseChip";

function ToolCalledMetadataItem({
  msg,
  idx,
  setActiveToolId,
  setView,
  view,
  activeToolId,
}: {
  msg: SmsMetadataRow;
  idx: number;
  setActiveToolId: (id: string | null) => void;
  setView: (view: string) => void;
  view: string;
  activeToolId: string | null;
}) {
  return (
    <div key={idx} className="flex w-fit flex-col gap-2 p-1">
      <BaseChip>{msg.function_call_name}</BaseChip>
      <div className="flex flex-row gap-2">
        {msg.function_call_value && (
          <button
            className={clsx(
              "rounded-lg border-2 border-skin-hover p-1 text-xs",
              view === "input" && "bg-skin-accentSecondary",
            )}
            onClick={() => {
              if (activeToolId === msg.id && view === "input") {
                setView("base");
                setActiveToolId(null);
                return;
              }
              setActiveToolId(msg.id);
              setView("input");
            }}
          >
            Show input
          </button>
        )}
        {msg.function_call_response && (
          <button
            className={clsx(
              "rounded-lg border-2 border-skin-hover p-1 text-xs",
              view === "output" && "bg-skin-accentSecondary",
            )}
            onClick={() => {
              if (activeToolId === msg.id && view === "output") {
                setView("base");
                setActiveToolId(null);
                return;
              }
              setActiveToolId(msg.id);
              setView("output");
            }}
          >
            Show output
          </button>
        )}
      </div>
    </div>
  );
}

export default ToolCalledMetadataItem;
